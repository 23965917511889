export const displayPageLoader = (dispatch) => {
  dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true } });
};

export const closePageLoader = (dispatch) => {
  dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false } });
};

export const displayLoginModal = (dispatch) => {
  dispatch({ type: 'DISPLAY_LOGIN_MODAL', payload: { open: true } });
};

export const closeLoginModal = (dispatch) => {
  dispatch({ type: 'DISPLAY_LOGIN_MODAL', payload: { open: false } });
}

export const displayAlert = (dispatch, type, message) => {
  dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type, duration: 6000, message } });
}

export const openConfirmDialog = (dispatch, action, message='') => {
  dispatch({ type: 'DISPLAY_CONFIRM_ACTION_MODAL', payload: { open: true, message, action } });
}

export const openTwoWayDialog = (dispatch, title, message, btn1, btn2, action1, action2) => {
  dispatch({
    type: 'DISPLAY_TWO_WAY_DIALOG',
    payload: { open: true, title, message, btn1, btn2, action1, action2 },
  });
}
