import React, { useContext } from 'react';
import Rating from '@material-ui/lab/Rating';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { store } from '../global-store/popupContext';
import { openConfirmDialog } from '../helpers/popupHelpers';

const TutorClassCard = ({ course, href }) => {
  const classes = useStyles();
  const { dispatch } = useContext(store);

  const deleteCourse = () => {
    alert('not deleted');
  };

  return (
    <div className="online-clses-item">
      <div className="onl-cls-img">
        <img alt={course.title} src={course.poster} />
      </div>
      <div className="onl-cls-content">
        <div className="oci-rating-div">
          <p>{course.title}</p>
          <div className="crd-div">
            <Rating value={3.5} precision={0.5} readOnly />
            <div className="ctc-avatar" title={course.title}>
              <img alt={course.title} src={avatarPlaceholder} />
            </div>
          </div>
        </div>
        <p className="oci-mid-p">{course.description}</p>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <ColorButton
            variant="contained"
            color="primary"
            classes={{root: classes.btnRoot}}
            href={href ? href + course.course_id : "/update-classes/" + course.course_id}
          >
            View Class
          </ColorButton>
          <Link
            onClick={() => openConfirmDialog(dispatch, deleteCourse)}
            title="delete course"
          >
            <Delete style={{color:'#e42020'}} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TutorClassCard;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}));
