import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ClearRounded } from '@material-ui/icons';
import { prepschoolAuth } from '../apis/prepschoolAuth';
import { store } from '../global-store/popupContext';
import useValidate from '../custom-hooks/useValidate';

const initialValues = {
  title: { value: '', validation: true },
  institution: { value: '', validation: true },
  from: { value: '', validation: true },
  to: { value: '', validation: true },
}

const AddQualificationComp = (props) => {
  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [qualifications, setQualifications] = useState([]);

  const disableSubmitBtn = useValidate(formValues);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = {
      value: value || '',
      validation: regEx.test(value) ? true : false
    };

    setFormValues(formTemp);
  };

  const addQualification = () => {
    const qual = {
      title: formValues.title.value,
      institution: formValues.institution.value,
      from: formValues.from.value,
      to: formValues.to.value,
    };
    setFormValues(initialValues);
    setQualifications([...qualifications, qual]);
  };

  const removeQualification = (index) => {
    const oldQuals = [...qualifications];
    oldQuals.splice(index, 1);
    setQualifications(oldQuals);
  };

  const updateGeneralValidation = useCallback(() => {
    qualifications[0]
      ? props.setExternalInput('qualifications', formValues, true)
      : props.setExternalInput('qualifications', '', false)
  }, [qualifications, formValues, props]);
  
  useEffect(() => { updateGeneralValidation(); }, [qualifications, updateGeneralValidation]);

  const submitQualifications = useCallback(() => {
    Promise.all(qualifications.map((qual) => {
      const form = {
        title: qual.title,
        institution: qual.institution,
        year: qual.from + ' - ' + qual.to,
      };

      return prepschoolAuth().post('/qualifications', form)
        .then(() => {
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 2000, message: 'Qualifications added' } });
        })
        .catch((err) => {
          if (err.response) return dispatch({ 
            type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error }
          });
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Request could not be completed' } });
        });
    }));
  }, [qualifications, dispatch]);

  useEffect(() => {
    if (props.submitReady) submitQualifications();
  }, [props.submitReady, submitQualifications]);

  return (
    <div style={{width:'100%',marginBottom:30}}>
      <div style={{display:'flex',flexWrap:'wrap',width:'100%',justifyContent:'space-between',marginBottom:20}}>
        <TextField
          className="form-fields"
          style={{minWidth:200,flex:1}}
          fullWidth
          label="Enter qualification"
          variant="standard"
          value={formValues.title.value}
          error={!formValues.title.validation}
          onChange={(e) => {
            handleFormInput('title', e.target.value, /./gi);
          }}
        />
        <TextField
          className="form-fields"
          style={{minWidth:200,flex:1}}
          fullWidth
          label="Enter institution"
          variant="standard"
          value={formValues.institution.value}
          error={!formValues.institution.validation}
          onChange={(e) => {
            handleFormInput('institution', e.target.value, /./gi);
          }}
        />
        <div style={{display:'flex',width:230,justifyContent:'space-between'}}>
          <Autocomplete
            style={{width:'100%',maxWidth:100}}
            className="form-fields"
            options={years}
            inputValue={formValues.from.value}
            renderInput={params => (
              <TextField
                {...params}
                label="From"
                variant="standard"
                fullWidth
              />
            )}
            onChange={(e, value) => handleFormInput('from', value, /./gi)}
          />
          <Autocomplete
            style={{width:'100%',maxWidth:100}}
            className="form-fields"
            options={years}
            inputValue={formValues.to.value}
            renderInput={params => (
              <TextField
                {...params}
                label="To"
                variant="standard"
                fullWidth
              />
            )}
            onChange={(e, value) => handleFormInput('to', value, /./gi)}
          />
        </div>
      </div>
      <div>
        {qualifications.map((qual) => {
          return (
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',margin:'0 0 20px',overflowX:'auto'}}>
              <p style={{margin:'0',fontWeight:'bold'}}>{qual.title}</p>
              <ClearRounded
                style={{cursor:'pointer',color:'red'}}
                onClick={removeQualification}
              />
            </div>
          )
        })}
      </div>
      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
        <ColorButton
          variant="outlined"
          color="primary"
          fullWidth
          onClick={addQualification}
          disabled={disableSubmitBtn}
        >
          add qualification
        </ColorButton>
      </div>
    </div>
  );
};

export default AddQualificationComp;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      borderColor: '#0c946e',
      color: '#0c946e',
    },
    backgroundColor: 'white',
    borderColor: '#0FD9A0',
    height: 45,
    color: '#0FD9A0',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200,
    alignSelf: 'center',
  },
}))(Button);

const years = [
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021'
];