import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Chip, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import { store } from '../../global-store/popupContext';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import {
  validate, handleFormInput, handleFileInput, handleArrayInput
} from '../../helpers/validation';
import { closePageLoader, displayAlert, displayPageLoader } from '../../helpers/popupHelpers';
import { subjectCategories } from '../../helpers/subjectCategories';

const ClassInfoTab = ({ course, setCourse, setComp }) => {
  const initialValues = {
    title: { value: course ? course.title : '', validation: true },
    description: { value: course ? course.description : '', validation: true },
    subject_tags: { value: course ? course.subject_tags : '', validation: true },
    duration: { value: course ? course.duration : '', validation: true },
    level: { value: course ? course.level : '', validation: true },
    price: { value: course ? course.price : '', validation: true },
    poster: { value: course ? course.price : '', validation: true },
  };

  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [prepschoolSubjects, setPrepschoolSubjects] = useState(subjectCategories);
  const [subjects] = useState(JSON.parse(localStorage.getItem('prepschool_subjects')));
  const [fadeBtn, setFadeBtn] = useState(true);

  useEffect(() => validate(formValues, setFadeBtn), [formValues]);

  const fetchSubjects = useCallback(() => {
    if (subjects) return setPrepschoolSubjects(subjects);
    prepschoolClasses().get('/subjects')
      .then((data) => {
        setPrepschoolSubjects(data.data.data);
        localStorage.setItem('prepschool_subjects', JSON.stringify(data.data.data));
      })
      .catch(err => console.log(err));
  }, [subjects]);
  
  useEffect(() => { fetchSubjects(); }, [fetchSubjects]);

  const returnSubmitForm = () => {
    const formData = new FormData();

    const subs = formValues.subject_tags.value;
    let subject_tags = ' ';
    subject_tags += prepschoolSubjects
      .filter((item) => subs.includes(item.title))
      .map((item) => item.id).join(', ') + ',';

    formData.append('title', formValues.title.value);
    formData.append('description', formValues.description.value);
    formData.append('subject_tags', subject_tags);
    formData.append('duration', formValues.duration.value);
    formData.append('level', formValues.level.value);
    formData.append('no_of_lessons', 40);
    formData.append('price', formValues.price.value);
    formData.append('image', formValues.poster.value);

    return formData;
  }

  const submitCourse = () => {
    displayPageLoader(dispatch);

    const formData = returnSubmitForm();

    prepschoolClasses().post('/courses', formData)
      .then((data) => {
        closePageLoader(dispatch);
        const { data: course, message } = data.data;
        history.push('/update-classes/' + course.course_id);
        setComp(2);
        return displayAlert(dispatch, 'success', message);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed 3');
      });
  };

  const updateCourse= () => {
    displayPageLoader(dispatch);

    const formData = returnSubmitForm();

    prepschoolClasses().put('/courses/' + course.course_id, formData)
      .then((data) => {
        closePageLoader(dispatch);
        const { message } = data.data;
        displayAlert(dispatch, 'success', message);
        setCourse(data.data.data);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed 1');
      });
  };

  return (
    <div className="ucpage-div2">
      <h2>Course overview</h2>
      <p>Add course details to help student and parents discover your course</p>
      <hr />
      <div className="ucpage-citab-div" style={{marginBottom:10}}>
        <label>Course Title</label>
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          fullWidth
          variant="outlined"
          value={formValues.title.value}
          error={!formValues.title.validation}
          onChange={(e) => {
            handleFormInput('title', e.target.value, /^/gi, formValues, setFormValues);
          }}
        />
        <label>Course Description</label>
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          fullWidth
          multiline
          rows={5}
          variant="outlined"
          value={formValues.description.value}
          error={!formValues.description.validation}
          onChange={(e) => {
            handleFormInput('description', e.target.value, /^/gi, formValues, setFormValues);
          }}
        />
        <label>Categories</label>
        <Autocomplete
          style={{marginBottom:20}}
          multiple
          id="tags-filled"
          options={prepschoolSubjects.map((option) => option.title)}
          fullWidth
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip fullWidth variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Choose related subjects"
              placeholder="you can select multiple"
              error={!formValues.subject_tags.validation}
              helperText={!formValues.subject_tags.validation ? 'can not choose more than 30' : null}
            />
          )}
          onChange={(e, value) => {
            handleArrayInput('subject_tags', value, 1, 30, formValues, setFormValues);
          }}
        />
        <label>Course Duration</label>
        <Autocomplete
          className="form-fields"
          options={['0 - 1hours', '1 - 2hours', '2 - 3hours', '3 - 4hours', '4 - 5hours', '5 - 6hours']}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              //error={!formValues.role.validation}
              style={{ marginBottom:20,width:'100%' }}
            />
          )}
          value={formValues.duration.value}
          onChange={(e, value) => {
            handleFormInput('duration', value, /^/gi, formValues, setFormValues);
          }}
        />
        <label>Level</label>
        <Autocomplete
          className="form-fields"
          options={['JS1', 'JS2', 'JS3', 'SS1', 'SS2', 'SS3', 'secondary', 'tertiary']}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              style={{ marginBottom:20,width:'100%' }}
            />
          )}
          value={formValues.level.value}
          onChange={(e, value) => {
            handleFormInput('level', value, /^/gi, formValues, setFormValues);
          }}
        />
        <div style={{width:'100%',marginBottom:20}}>
          <div className={formValues.poster.validation !== true ? 'custom-file-field-error' : ''}
            style={{display:'flex',alignItems:'center',padding:5,borderRadius:4,border:'1px dashed #00000026'}}
          >
            <div className={classes.root}>
              <input
                className={classes.input}
                id="contained-button-file"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleFileInput('poster', e.target.files[0], 500000, formValues, setFormValues);
                }}
              />
              <label htmlFor="contained-button-file">
                <Button className={classes.btnUpload} variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
            </div>
            {<span>{formValues.poster.value !== '' ? formValues.poster.value.name : 'Upload poster image for your course'}</span>}
          </div>
          <span style={{color:'#e53935'}}>{formValues.poster.validation !== true ? formValues.poster.validation : ''}</span>
        </div>
        <label>Price (Naira)</label>
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          fullWidth
          variant="outlined"
          value={formValues.price.value}
          error={!formValues.price.validation}
          onChange={(e) => {
            handleFormInput('price', e.target.value, /^\d+$/gi, formValues, setFormValues);
          }}
        />
      </div>
      {
        course
          ? <ColorButton
              variant="contained"
              color="primary"
              onClick={updateCourse}
              disabled={fadeBtn}
            >
              update course
            </ColorButton>
          : <ColorButton
              variant="contained"
              color="primary"
              onClick={submitCourse}
              disabled={fadeBtn}
            >
              create course
            </ColorButton>
      }
    </div>
  );
};

export default ClassInfoTab;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    textTransform: 'capitalize',
    height: 45,
    color: 'white',
    width: 160,
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
