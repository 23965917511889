import React, { useState, useEffect, useCallback } from 'react';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import BookedLiveCourse from '../../components/BookedLiveCourse';
import DashboardLayout from '../../components/DashboardLayout';

const tabs = [
  { name: 'parents_dashboard', title: 'My Dashboard', href: '/users/parents/dashboard' },
  { name: 'find_lessons', title: 'Find lessons', href: '/user/findclasses' },
  { name: 'booking', title: 'Bookings', href: '/booked/courses' }
];

const ParentViewBookings = () => {
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [bookings, setBookings] = useState([]);
  const [reload, setReload] = useState(true);

  const fetchBooking = useCallback(() => {
    const params = { parent: user.user_id };

    prepschoolClasses().get('/bookings/live-courses', { params })
      .then((data) => {
        setBookings(data.data.data);
      })
      .catch(({ message, response }) => {
        console.log('error', message, response);
      })
  }, [user]);
  
  useEffect(() => { fetchBooking(); }, [reload, fetchBooking]);

  return (
    <DashboardLayout tabs={tabs} parentOrStudentOnly>
      <div className="parents-db-page">
        <section className="parents-db-sec2">
          <h4>Booked Live Online Classes</h4>
          <div>
            {bookings.map((item, idx) => {
              if (!item) return null;
              return (
                <BookedLiveCourse
                  key={idx}
                  booking={item}
                  reload={reload}
                  setReload={setReload}
                />
              );
            })}
          </div>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default ParentViewBookings;
