export const subjectCategories = [
  { id: 1, title: 'Languages' },
  { id: 2, title: 'Social studies' },
  { id: 3, title: 'Science & Nature' },
  { id: 4, title: 'Coding, Robotics & Tech' },
  { id: 5, title: 'Design' },
  { id: 6, title: 'Music' },
  { id: 7, title: 'Maths' },
  { id: 8, title: 'English & Creative' },
  { id: 9, title: 'Life Skills' },
  { id: 10, title: 'Entrepreneurship & Finance' },
  { id: 11, title: 'Arts & Crafts' },
  { id: 12, title: 'Games & Sports' },
  { id: 13, title: 'Competitions & Camps' },
];
