import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import prepschoolAuth from '../../apis/prepschoolAuth';
import { TextField } from '@material-ui/core';
import { store } from '../../global-store/popupContext';
import { ReactComponent as Logo } from '../../assets/images/prepschool-logo-blue.svg';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 40,
    backgroundColor: 'black',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    marginBottom: 20,
    maxWidth: 200,
  },
}));

const initialValues = {
  email: { value: '', validation: true },
}

function InitiateForgotPassword(props) {
  const classes = useStyles();
  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [fadeBtn, setFadeBtn] = useState(true);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
  }

  const validate = useCallback(() => {
    const valid = Object.values(formValues).every((value) => (
      value.value !== '' && value.validation
    ));

    setFadeBtn(!valid);
  }, [formValues]);
  
  useEffect(() => { validate(); }, [formValues, validate]);
  
  function requestResetPassword() {
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});
    const payload = { email: formValues.email.value };
    
    prepschoolAuth.post('/forgot-password', payload)
      .then(() => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 15000, message: 'An email has been sent to your inbox with further instructions' } });
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        console.log(err.message)
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  }

  return (
    <main className="open-corp-account">
      <div className="opencorpacclogo">
        <Link to="/" style={{color:'blue',fontWeight:600,textDecoration:'none'}}>
          <Logo />
        </Link>
      </div>
      <section>
        <h4 style={{marginBottom:0,marginTop:30}}>Forgot Password</h4>
        <p style={{maxWidth: 256,margin:'10px auto',color: '#000000',marginBottom:40}}>
          Enter your email for prepschool
        </p>
        <TextField
          className="form-fields"
          style={{marginBottom: 20, maxWidth: 300}}
          id="email-field"
          fullWidth
          label="Email"
          variant="outlined"
          value={formValues.email.value}
          error={!formValues.email.validation}
          onChange={(e) => handleFormInput('email', e.target.value.trim().toLowerCase(), /^[a-z\d][\w.-]+@[a-z\d]+\..+$/gi)}
        />
        <div>
          <Button
            classes={{root: classes.btnRoot}}
            variant="contained"
            color="primary"
            fullWidth
            onClick={requestResetPassword}
            disabled={fadeBtn}
          >
            Reset Password
          </Button>
        </div>
      </section>
    </main>
  );
}

export default InitiateForgotPassword;
