import React, { useState, useEffect, useCallback } from 'react';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import DashboardLayout from '../../components/DashboardLayout';
import OnlineClassesCard from '../../components/OnlineClassesCard';

const ViewTutorVideoCourses = () => {
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [tutorVideoCourses, setTutorVideoCourses] = useState([]);

  const tabs = [
    { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
    { name: 'profile', title: 'My Profile', href: '/tutor-profile/' + user.user_id },
    { name: 'live-courses', title: 'Live Courses', href: '/tutor/live-courses' }
  ];

  const fetchTutorVideoCourses = useCallback(() => {
    prepschoolClasses().get('/courses', { params: { tutor: user.user_id } })
      .then((data) => {
        setTutorVideoCourses(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => { fetchTutorVideoCourses(); }, [fetchTutorVideoCourses]);

  return (
    <DashboardLayout tabs={tabs} tutorsOnly>
      <div className="tutors-dashboard">
        <div className="parents-db-page">
          <section className="parents-db-sec2">
            <h4>My Video Courses</h4>
            <div className="online-clses-cont">
              {
                tutorVideoCourses.map((item, idx) => (
                  <OnlineClassesCard key={idx} course={item} />
                ))
              }
            </div>
          </section>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ViewTutorVideoCourses;
