import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router';
import DashboardLayout from '../components/DashboardLayout';
import TutorsFilter from '../components/TutorsFilter';
import OnlineClassesCard from '../components/OnlineClassesCard';
import { prepschoolClasses } from '../apis/prepschoolClasses';

const tabs = [
  { name: 'find_tutor', title: 'Find Tutors', href: '/user/findtutors' },
  { name: 'find_live_courses', title: 'Live Courses', href: '/user/live-courses' },
];

const FindClasses = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const pageLimit = 30;
  const queryParam = new URLSearchParams(props.location.search.slice(1));

  const initialValues = {
    skill: { value: queryParam.get('subjects') !== 'null' ? queryParam.get('subjects') : '' },
    price: { value: queryParam.get('price') !== 'null' ? queryParam.get('price') : '' },
    skip: { value: queryParam.get('skip') !== 'null' ? queryParam.get('skip') : '' },
  };

  const [courses, setCourses] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const findCourses = useCallback(() => {
    setCoursesLoading(true);
    const { skill, price, skip } = formValues;

    let queryString = `/user/findclasses?subjects=${skill.value}&price=${price.value}`;
    queryString += `&skip=${skip.value}&limit=${pageLimit}`;

    history.push(queryString);

    const params = {
      status: 'published', subject_tags: skill.value, price: price.value,
      skip: skip.value, limit: pageLimit,
    };
    prepschoolClasses().get('/courses', { params })
      .then((data) => {
        setCoursesLoading(false);
        setCourses(data.data.data);
      })
      .catch((err) => {
        setCoursesLoading(false);
        console.log(err);
      });
  }, [formValues, history]);
  
  useEffect(() => findCourses(), [formValues, findCourses]);

  const fetchNextPage = (page) => {
    const skip = (page - 1) * pageLimit;
    setFormValues({ ...formValues, skip: { value: skip } });
  }

  return (
    <DashboardLayout tabs={tabs} proceedUnAuthenticated>
      <TutorsFilter {...{ formValues, setFormValues }} />
      <section className="classes-page-sec tutors-page-sec">
        <h4>Video Courses</h4>
        <div className="ttps-search-res">
          <span><b>{courses.length} Lessons</b> matching your search</span>
          {/*<Autocomplete
            className="form-fields"
            options={[]}
            defaultValue="popularity"
            renderInput={params => (
              <TextField
                {...params}
                label="sort by"
                variant="standard"
                //error={!formValues.role.validation}
              />
            )}
            //value={formValues.role.value}
            //onChange={(e, value) => handleFormInput('role', value, /./gi)}
          />*/}
        </div>
        {coursesLoading
          ? <div>Loading courses...</div>
          : null
        }
        <div className="classes-found-cont">
          {courses.map((item, idx) => {
            return (<OnlineClassesCard key={idx} course={item} />);
          })}
        </div>
      </section>
      <div className={classes.root}>
        <Pagination
          count={30}
          variant="outlined"
          shape="rounded"
          page={Number(formValues.skip.value/pageLimit) + 1 || 1}
          onChange={(event, page) => fetchNextPage(page)}
        />
      </div>
    </DashboardLayout>
  );
};

export default FindClasses;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width:'80%',
      margin:'auto',
      marginTop: theme.spacing(2),
      marginBottom: 50,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
