import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StateProvider } from './global-store/popupContext';
import Alert from './components/popups/Alert';
import PageLoader from './components/popups/PageLoader';
import LoginModal from './components/popups/LoginModal';
import ConfirmAction from './components/popups/ConfirmAction';
import TwoWayDialog from './components/popups/TwoWayDialog';

ReactDOM.render(
  (
    <StateProvider>
      <App />
      <Alert />
      <PageLoader />
      <LoginModal />
      <ConfirmAction />
      <TwoWayDialog />
    </StateProvider>
  ),
  document.querySelector('#root')
);
