import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import DashboardLayout from '../components/DashboardLayout';
import TutorTile from '../components/TutorTile';
import TutorsFilter from '../components/TutorsFilter';
import { prepschoolAuth } from '../apis/prepschoolAuth';
import { useHistory } from 'react-router';

const FindTutors = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const pageLimit = 12;
  const queryParam = new URLSearchParams(props.location.search.slice(1));

  const initialValues = {
    skill: { value: queryParam.get('subjects') !== 'null' ? queryParam.get('subjects') : '' },
    price: { value: queryParam.get('price') !== 'null' ? queryParam.get('price') : '' },
    skip: { value: queryParam.get('skip') !== 'null' ? queryParam.get('skip') : '' },
  };

  const [tutors, setTutors] = useState([]);
  const [tutorsLoading, setTutorsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const fetchTutors = useCallback(() => {
    setTutorsLoading(true);

    const { skill, price, skip } = formValues;

    let queryString = `/user/findtutors?subjects=${skill.value}&price=${price.value}`;
    queryString += `&skip=${skip.value}&limit=${pageLimit}`;

    history.push(queryString);

    prepschoolAuth().get('/tutors', {
      params: { subjects: skill.value, price: price.value, skip: skip.value || 0, limit: pageLimit }
    })
      .then((data) => {
        setTutorsLoading(false);
        setTutors(data.data.data);
      })
      .catch(() => {
        setTutorsLoading(false);
      });
  }, [formValues, history]);
  
  useEffect(() => fetchTutors(), [formValues, fetchTutors]);
    
  const fetchNextPage = (page) => {
    const skip = (page - 1) * pageLimit;
    setFormValues({ ...formValues, skip: { value: skip } });
  }

  return (
    <DashboardLayout proceedUnAuthenticated>
      <TutorsFilter {...{ formValues, setFormValues }} />
      <section className="tutors-page-sec section-sizing">
        <div className="ttps-search-res">
          <span><b>{tutors.length} Tutor(s)</b> matching your search</span>
          {/*<Autocomplete
            className="form-fields"
            options={[]}
            defaultValue="popularity"
            renderInput={params => (
              <TextField
                {...params}
                label="sort by"
                variant="standard"
                //error={!formValues.role.validation}
              />
            )}
            //value={formValues.role.value}
            //onChange={(e, value) => handleFormInput('role', value, /./gi)}
          />*/}
        </div>
        {tutorsLoading
          ? <div>Loading tutors...</div>
          : null
        }
        {tutors.map((item, idx) => {
          return <TutorTile key={idx} tutor={item} />;
        })}
      </section>
      <div className={classes.root}>
        <Pagination
          count={30}
          variant="outlined"
          shape="rounded"
          page={Number(formValues.skip.value/pageLimit) + 1 || 1}
          onChange={(event, page) => fetchNextPage(page)}
        />
      </div>
    </DashboardLayout>
  );
};

export default FindTutors;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width:'80%',
      margin:'auto',
      marginTop: theme.spacing(2),
      marginBottom: 50,
      display: 'flex',
      justifyContent: 'center'
    },
  },
}));