import { prepschoolClasses } from "../apis/prepschoolClasses";
import { closePageLoader, displayAlert, displayPageLoader } from "./popupHelpers";

export const initiateCoursePayment = (course, beneficiaries, user, dispatch, category) => {
  const title = category ? category : 'VIDEO_COURSE';
  const { course_id, price } = course;
  const customer = user.user_id;
  const { email, phone: phonenumber, first_name, last_name } = user;
  const name = `${first_name} ${last_name}`;
  const mode = beneficiaries.length === 1 ? 'single' : 'multiple';
  const tx_ref = `${customer}-${(new Date()).toISOString()}`;
  const payload = beneficiaries.map((item) => {
    return { 
      title, context: course_id, customer, amount: price, beneficiary: item.user_id,
      mode, tx_ref,
      customerDetails: { email, phonenumber, name }
    }
  });

  displayPageLoader(dispatch);
  return prepschoolClasses().post('/payments/initialize', payload)
    .then((data) => {
      closePageLoader(dispatch);
      const { link } = data.data.data;
      window.open(link);
    })
    .catch(({ message, response }) => {
      closePageLoader(dispatch);
      if (response) return displayAlert(dispatch, 'error', response.data.error);
      displayAlert(dispatch, 'error', message);
    });
};

export const initiateTutorPayment = () => {

};
