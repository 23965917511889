import React, { createContext, useReducer } from 'react';

const initialAlertState = { open: false, type: 'success', duration: 6000, message: '', };
const initialPageLoaderState = { open: false };
const initialLoginModalState = { open: false };
const initialConfirmActionState = { open: false, message: '', action: () => {} };
const initialTwoWayDialogActionState = {
  open: false, title: '', message: '', btn1: '', btn2: '', action1: () => {}, action2: () => {},
};

const initialState = {
  alert: initialAlertState,
  pageLoader: initialPageLoaderState,
  loginModal: initialLoginModalState,
  confirmActionModal: initialConfirmActionState,
  twoWayDialog: initialTwoWayDialogActionState,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'DISPLAY_ALERT':
        return { ...state, alert: { ...state.alert, ...action.payload } };
      case 'DISPLAY_PAGE_LOADER':
        return { ...state, pageLoader: { ...state.pageLoader, ...action.payload } };
      case 'DISPLAY_LOGIN_MODAL':
        return { ...state, loginModal: { ...state.loginModal, ...action.payload } };
      case 'DISPLAY_CONFIRM_ACTION_MODAL':
        return { ...state, confirmActionModal: { ...state.confirmActionModal, ...action.payload } };
      case 'DISPLAY_TWO_WAY_DIALOG':
        return { ...state, twoWayDialog: { ...state.twoWayDialog, ...action.payload } };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{state, dispatch}}>{children}</Provider>;
}

export { store, StateProvider };
