import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "./assets/styles/main.scss";
import Index from './pages/Index';
import history from './history';
import Login from './pages/authentication/Login';
import Signup from './pages/authentication/Signup';
import FindTutors from './pages/FindTutors';
import FindClasses from './pages/FindClasses';
import ParentsDashboard from './pages/parents/ParentsDashboard';
// import NotFoundPage from './pages/NotFoundPage';
import LearningPlanPage from './pages/students/LearningPlanPage';
import ClassDetailPage from './pages/ClassDetailPage';
import ParentEnrollChild from './pages/parents/ParentEnrollChild';
import AddAChild from './pages/parents/AddAChild';
import BecomeATutor from './pages/tutors/BecomeATutor';
import TutorWelcomePage from './pages/tutors/TutorWelcomePage';
import UploadClasses from './pages/tutors/UploadClasses';
import PreviewClass from './pages/tutors/PreviewClass';
import PreviewLiveClass from './pages/tutors/PreviewLiveClass';
import ViewTutor from './pages/ViewTutor';
import TutorProfile from './pages/TutorProfile';
import HireTutor from './pages/parents/HireTutor';
import TutorsDashboard from './pages/tutors/TutorsDashboard';
import CourseArena from './pages/CourseArena';
import Tester from './pages/Tester';
import EmailVerificationSent from './pages/authentication/EmailVerificationSent';
import EmailVerificationStatus from './pages/authentication/EmailVerificationStatus';
import InitiateForgotPassword from './pages/authentication/InitiateForgotPassword';
import ResetForgotPassword from './pages/authentication/ResetForgotPassword';
import StudentsDashboard from './pages/students/StudentsDashboard';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import ViewBookedCourses from './pages/parents/ParentViewBookings';
import TutorViewBookings from './pages/tutors/TutorViewBookings';
import UploadLiveCourse from './pages/tutors/UploadLiveCourse';
import LiveCourseBooking from './pages/students/LiveCourseBooking';
import LiveCourseArena from './pages/students/LiveCourseArena';
import ViewTutorLiveCourses from './pages/tutors/ViewTutorLiveCourses';
import ViewTutorVideoCourses from './pages/tutors/ViewTutorVideoCourses';
import ChildrenDashboard from './pages/children/ChildrenDashboard';
import FindLiveCourses from './pages/FindLiveCourses';
import UserViewTutor from './pages/UserViewTutor';

function App() {

  
  return (
    <div>
      <Router history={history}>
        <div style={{background:'white'}}>
          <Route path="/" exact component={Index} />
          <Route path="/user/findtutors" exact component={FindTutors} />
          <Route path="/user/findclasses" exact component={FindClasses} />
          <Route path="/user/live-courses" exact component={FindLiveCourses} />
          <Route path="/users/classes/:id" exact component={ClassDetailPage} />
          <Route path="/view-tutor/:id" exact component={ViewTutor} />
          <Route path="/tutor-profile/:id" exact component={TutorProfile} />
          <Route path="/course-arena/:id/:index" exact component={CourseArena} />
          <Route path="/tutors/:tutor_id/:tutor_name" exact component={UserViewTutor} />

{/*---------------------------------- Authentication ------------------------------------------------*/}
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/verification/email-sent" exact component={EmailVerificationSent} />
          <Route path="/verify/email" exact component={EmailVerificationStatus} />
          <Route path="/forgot-password/initiate" exact component={InitiateForgotPassword} />
          <Route path="/forgot-password/change" exact component={ResetForgotPassword} />

{/*------------------------------------- Parents --------------------------------------------------*/}
          <Route path="/users/parents/dashboard" exact component={ParentsDashboard} />
          <Route path="/users/enroll-child/:course_id" exact component={ParentEnrollChild} />
          <Route path="/add-a-child" exact component={AddAChild} />
          <Route path="/hire-tutor/:id" exact component={HireTutor} />
          <Route path="/booked/courses" exact component={ViewBookedCourses} />
          <Route path="/users/children/dashboard" exact component={ChildrenDashboard} />

{/*------------------------------------- Tutors --------------------------------------------------*/}
          <Route path="/become-a-tutor" exact component={BecomeATutor} />
          <Route path="/users/tutors/dashboard" exact component={TutorsDashboard} />
          <Route path="/tutor-welcome" exact component={TutorWelcomePage} />
          <Route path="/upload-classes" exact component={UploadClasses} />
          <Route path="/update-classes/:id" exact component={UploadClasses} />
          <Route path="/upload-classes/live" exact component={UploadLiveCourse} />
          <Route path="/update-classes/live/:id" exact component={UploadLiveCourse} />
          <Route path="/preview-class/:id/:index" exact component={PreviewClass} />
          <Route path="/preview-live-class/:id" exact component={PreviewLiveClass} />
          <Route path="/tutor/bookings/accept" exact component={TutorViewBookings} />
          <Route path="/tutor/live-courses" exact component={ViewTutorLiveCourses} />
          <Route path="/tutor/video-courses" exact component={ViewTutorVideoCourses} />

{/*------------------------------------- Students --------------------------------------------------*/}
          <Route path="/users/students/dashboard" exact component={StudentsDashboard} />
          <Route path="/users/learning-plan" exact component={LearningPlanPage} />
          <Route path="/students/booking/live-course/:course" exact component={LiveCourseBooking} />
          <Route path="/students/live-course/view" exact component={LiveCourseArena} />

{/*------------------------------------- Payments --------------------------------------------------*/}
          <Route path="/payments/course/status" exact component={PaymentSuccessPage} />
          
          <Route path="/tester" exact component={Tester} />
          {/*<Route path="/" component={NotFoundPage} />*/}
        </div>
      </Router>
    </div>
  );
}

export default App;
