import React, { useState, useEffect } from 'react';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { prepschoolLiveClassArena } from '../../apis/urls';
import DashboardLayout from '../../components/DashboardLayout';
import TutorsLiveClassCard from '../../components/TutorsLiveClassCard';

const ViewTutorLiveCourses = () => {
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [tutorLiveCourses, setTutorLiveCourses] = useState([]);

  useEffect(() => {
    fetchTutorLiveCourses();
  }, []);
  
  const tabs = [
    { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
    { name: 'profile', title: 'My Profile', href: '/tutor-profile/' + user.user_id },
    { name: 'video-courses', title: 'Video Courses', href: '/tutor/video-courses' }
  ];

  const fetchTutorLiveCourses = () => {
    prepschoolClasses().get('/live-courses', { params: { status: 'published' } })
      .then((data) => {
        setTutorLiveCourses(data.data.data);
      })
      .catch((err) => {
        console.log(err)
      });
  };

  return (
    <DashboardLayout tabs={tabs} tutorsOnly>
      <div className="tutors-dashboard">
        <div className="parents-db-page">
          <section className="parents-db-sec2">
            <h4>Your Live Courses</h4>
            <div className="online-clses-cont">
              {
                tutorLiveCourses.map((item, idx) => (
                  <TutorsLiveClassCard
                    key={idx} course={item} 
                    href={`${prepschoolLiveClassArena}?token=${user.token}&topic=${item.title}`}
                  />
                ))
              }
            </div>
          </section>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ViewTutorLiveCourses;
