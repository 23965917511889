import React from 'react';
import { Avatar, Link, makeStyles } from '@material-ui/core';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';

const RegisteredClassesCard = ({ bookings }) => {
  const classes = useStyles();

  if (!bookings.length) return (
    <div className="tutor-new-bookings-card registered-classes-comp">
      <div className="reg-cl-top-div">
        <span>New Bookings</span>
        <b><Link color="inherit" href="/tutor/bookings/accept">{0}</Link></b>
      </div>
    </div>
  );
  
  return (
    <div className="tutor-new-bookings-card registered-classes-comp">
      <div className="reg-cl-top-div">
        <span>New Bookings</span>
        <b><Link color="inherit" href="/tutor/bookings/accept">{bookings.length}</Link></b>
      </div>
      {
        bookings.map((item) => {
          const noOfChildren = item.children.split(',').length;
          return (
            <div className="booking-tile">
              <Avatar alt="prepschool tutor" src={avatarPlaceholder} className={classes.largeAvatar} />
              <div className="booking-tile-detail">
                <div>
                  <h3>{item.title}</h3>
                  <span>{item.children.split(',').length} child(ren)</span>
                  <p>{noOfChildren * Number(item.price)}</p>
                </div>
                <div className="booking-status1 booking-status">{item.booking_status}</div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default RegisteredClassesCard;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
  largeAvatar: {
    width: 90,
    height: 90,
  },
}));
