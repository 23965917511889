import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import StepsIndicator from '../components/StepsIndicator';
import { ReactComponent as ArrowDown } from '../assets/images/arrow-down.svg';
import SearchComponent from '../components/SearchComponent';
import OnlineClassesCard from '../components/OnlineClassesCard';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { findRoleDashboard } from '../helpers/authHelpers';

const tabs = [
  { name: 'find_tutor', title: 'Find Tutor', href: '/user/findtutors' },
  { name: 'find_lessons', title: 'Find lessons', href: '/user/findclasses' },
  { name: 'become_tutor', title: 'Become a Tutor', href: '/signup?role=tutor' }
];

const Index = () => {
  const history = useHistory();

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  useEffect(() => {
    if (user && user.user_id) findRoleDashboard(user.role, history);
  }, [history, user]);

  return (
    <div className="index-page-container">
      <section className="index-page-intro-sec" id="idp-sec1">
        <Navigation tabs={tabs} />
        <div className="ipi-content">
          <div className="idp-sec-title-div two-col-flex">
            <div className="idp-sec-title">
              <h3>Preparing your children for success</h3>
              <p>We provide a convenient flexible indigenous platform for students to learn new skill that will help develop them.</p>
              <SearchComponent />
              {/*<Button variant="contained">music tutor</Button>*/}
            </div>
            <div className="idp-sec-div2"></div>
          </div>
          <div className="next-page-pointer">
            <div className="npp-div1"></div>
            <div className="npp-div2">
              <div>
                <Link style={{}} href="/#idp-sec2"><ArrowDown /></Link>
              </div>
            </div>
            <div className="npp-div3"></div>
          </div>
        </div>
      </section>
      <section className="index-page-online-classes-sec" id="idp-sec2">
        <div className="idpocs-div1">
          <h3>live online classes and camps</h3>
          <p>Explore any interest over live video chat! Discover fun, social, and safe learning experiences for kids ages 3-18 led by passionate tutors</p>
        </div>
        <div className="idpocs-div2">
          <div className="idpocs-div2-div-cont">
            <OnlineClassesCard />
            <OnlineClassesCard />
            <OnlineClassesCard />
          </div>
        </div>
      </section>
      <section className="idp-section3 index-page-online-classes-sec" id="idp-sec3" style={{background:'white'}}>
        <div className="idpocs-div1">
          <h3>How it works</h3>
          <p>Search for Tutors, Book lessons Start learning</p>
        </div>
        <div className="idpocs-div2">
          <StepsIndicator />
          <div className="ipocs-flex-cont two-col-flex">
            <div className="ipoc-fc-item1">
              <img alt="laptop in display" />
            </div>
            <div className="ipoc-fc-item2">
              <h4>Search for Tutors, Book lessons Start learning</h4>
              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally .</p>
              <ColorButton
                variant="contained"
                color="primary"
                fullWidth
                href="/user/findtutors"
              >
                request here
              </ColorButton>
            </div>
          </div>
        </div>
      </section>
      <section className="idp-section4 index-page-online-classes-sec" id="idp-sec4">
        <div className="idpocs-div1">
          <h3>Testimonials</h3>
          <p>What we've heard about us</p>
        </div>
        <div className="flex-col">
          <div className="avatar-div">
            <img src={avatarPlaceholder} alt="prepschool testimonial avatar" />
          </div>
          <i>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain </i>
          <p>Bismark Achodo</p>
        </div>
      </section>
      <section className="idp-reasons-sec" id="idp-sec5">
        <div className="idp-reasons-intro">
          <h2>A few reasons to give us a try</h2>
        </div>
        <div className="idp-btb-reasons">
          <div className="bullet-title-body">
            <div className="idp-bullet">1</div>
            <div className="idp-title-body">
              <h4>Many tutors from various fields</h4>
              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain</p>
            </div>
          </div>
          <div className="bullet-title-body">
            <div className="idp-bullet">2</div>
            <div className="idp-title-body">
              <h4>Learn anywhere you want</h4>
              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain</p>
            </div>
          </div>
          <div className="bullet-title-body">
            <div className="idp-bullet">3</div>
            <div className="idp-title-body">
              <h4>Record and repeat later</h4>
              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain</p>
            </div>
          </div>
          <div className="bullet-title-body">
            <div className="idp-bullet">4</div>
            <div className="idp-title-body">
              <h4 className="idb-btb-title">Available whenever you need help</h4>
              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Index;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}))(Button);