import React, { useState, useEffect, useCallback } from 'react';
import { prepschoolAuth } from '../apis/prepschoolAuth';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import DashboardLayout from '../components/DashboardLayout';
import OnlineClassesCard from '../components/OnlineClassesCard';
import TutorBlock from '../components/TutorBlock';

const tabs = [
  { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
  { name: 'courses', title: 'My Courses', href: '/user/findclasses' },
  { name: 'bookings', title: 'View Bookings', href: '/tutor/bookings/accept' }
];

const ViewTutor = (props) => {
  const [tutorCourses, setTutorCourses] = useState([]);
  const [tutorLiveCourses, setTutorLiveCourses] = useState([]);
  const [tutor, setTutor] = useState(null);

  const fetchTutorCourses = useCallback(() => {
    const tutorUserId = props.match.params.id;
    prepschoolClasses().get('/courses', { params: { tutor: tutorUserId } })
      .then((data) => {
        setTutorCourses(data.data.data);
      })
      .catch();
  }, [props]);
  
  useEffect(() => { fetchTutorCourses(); }, [fetchTutorCourses]);

  const fetchTutorLiveCourses = useCallback(() => {
    const tutorUserId = props.match.params.id;
    prepschoolClasses().get('/live-courses', { params: { tutor: tutorUserId } })
      .then((data) => {
        setTutorLiveCourses(data.data.data);
      })
      .catch();
  }, [props]);
  
  useEffect(() => { fetchTutorLiveCourses(); }, [fetchTutorLiveCourses]);

  const fetchTutor = useCallback(() => {
    prepschoolAuth().get('/tutors/' + props.match.params.id)
      .then((data) => {
        setTutor(data.data.data);
      })
      .catch();
  }, [props]);
  
  useEffect(() => { fetchTutor(); }, [fetchTutor]);

  return (
    <DashboardLayout tabs={tabs}>
      <section className="view-tutor-sec section-sizing">
        <div className="vtsec-top-block">
          <TutorBlock tutor={tutor} />
        </div>
        {tutorCourses[0]
          ?
            <>
              <h4>Video Classes Offered</h4>
              <div className="tblock-classes">
                {tutorCourses.map((item, idx) => (
                  <OnlineClassesCard key={idx} course={item} />
                ))}
              </div>
            </>
          : null
        }
        {/*user.role !== 'student' &&*/ tutorLiveCourses[0]
          ? <>
              <h4>Live Classes Offered</h4>
              <div className="tblock-classes">
                {tutorLiveCourses.map((item, idx) => (
                  <OnlineClassesCard
                    key={idx}
                    course={item}
                    nextPage={`/hire-tutor/${props.match.params.id}`}
                    //action={() => history.push(`/tutor-profile/${tutor.user_id}`)}
                  />
                ))}
              </div>
            </>
          : null
        }
      </section>
    </DashboardLayout>
  );
};

export default ViewTutor;
