import React, { useEffect } from 'react';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { checkAuthValidity } from '../helpers/authHelpers';
import { ReactComponent as Logo } from '../assets/images/prepschool-logo.svg';

const OnboardingLayout = (props) => {
  const history = useHistory();
  useEffect(() => {
    if (checkAuthValidity()) history.push('/');
  }, [history]);

  return (
    <main className="onbdg-main">
      <div className="onbdg-top-ribbon">
        <Link className="prepschool-link" href="/">
          <Logo />
        </Link>
      </div>
      <div className="onbdg-form-bkg">
        <div className="onbdg-form-cont">
          {props.children}
        </div>
      </div>
    </main>
  );
};

export default OnboardingLayout;
