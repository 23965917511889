import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ClearRounded } from '@material-ui/icons';
import { prepschoolAuth } from '../apis/prepschoolAuth';
import { store } from '../global-store/popupContext';
import useValidate from '../custom-hooks/useValidate';

const initialValues = {
  title: { value: '', validation: true },
  company: { value: '', validation: true },
  from: { value: '', validation: true },
  to: { value: '', validation: true },
}

const AddWorkExperienceComp = (props) => {
  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [experiences, setExperiences] = useState([]);

  const disableSubmit = useValidate(formValues);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = {
      value: value || '',
      validation: regEx.test(value) ? true : false
    };

    setFormValues(formTemp);
  }

  const addExperience = () => {
    const exp = {
      title: formValues.title.value,
      company: formValues.company.value,
      from: formValues.from.value,
      to: formValues.to.value,
    };
    setFormValues(initialValues);
    setExperiences([...experiences, exp]);
  };

  const removeExperience = (index) => {
    const oldExps = [...experiences];
    oldExps.splice(index, 1);
    setExperiences(oldExps);
  };

  const updateGeneralValidation = useCallback(() => {
    experiences[0]
      ? props.setExternalInput('experience', formValues, true)
      : props.setExternalInput('experience', '', false)
  }, [experiences, formValues, props]);
  
  useEffect(() => { updateGeneralValidation(); }, [experiences, updateGeneralValidation]);

  const submitExperience = useCallback(() => {
    Promise.all(experiences.map((exp) => {
      const form = {
        title: exp.title,
        company: exp.company,
        year: exp.from + ' - ' + exp.to,
      };

      return prepschoolAuth().post('/experience', form)
        .then(() => {
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 2000, message: 'Experience added' } });
        })
        .catch((err) => {
          if (err.response) return dispatch({ 
            type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error }
          });
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Request could not be completed' } });
        });
    }));
  }, [experiences, dispatch]);
  
  useEffect(() => {
    if (props.submitReady) submitExperience();
  }, [props.submitReady, submitExperience]);

  return (
    <div style={{width:'100%',marginBottom:30}}>
      <div style={{display:'flex',flexWrap:'wrap',width:'100%',justifyContent:'space-between',marginBottom:20}}>
        <TextField
          className="form-fields"
          style={{minWidth:200,flex:1}}
          fullWidth
          label="Enter work experience"
          variant="standard"
          value={formValues.title.value}
          error={!formValues.title.validation}
          onChange={(e) => {
            handleFormInput('title', e.target.value, /./gi);
          }}
        />
        <TextField
          className="form-fields"
          style={{minWidth:200,flex:1}}
          fullWidth
          label="Enter company"
          variant="standard"
          value={formValues.company.value}
          error={!formValues.company.validation}
          onChange={(e) => {
            handleFormInput('company', e.target.value, /./gi);
          }}
        />
        <div style={{display:'flex',width:230,justifyContent:'space-between'}}>
          <Autocomplete
            style={{width:'100%',maxWidth:100}}
            className="form-fields"
            options={years}
            inputValue={formValues.from.value}
            renderInput={params => (
              <TextField
                {...params}
                label="From"
                variant="standard"
                fullWidth
                error={false}
              />
            )}
            onChange={(e, value) => handleFormInput('from', value, /./gi)}
          />
          <Autocomplete
            style={{width:'100%',maxWidth:100}}
            className="form-fields"
            options={years}
            inputValue={formValues.to.value}
            renderInput={params => (
              <TextField
                {...params}
                label="To"
                variant="standard"
                fullWidth
                error={false}
              />
            )}
            onChange={(e, value) => handleFormInput('to', value, /./gi)}
          />
        </div>
      </div>
      <div>
        {experiences.map((qual) => {
          return (
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',margin:'0 0 20px',overflowX:'auto'}}>
              <p style={{margin:'0',fontWeight:'bold'}}>{qual.title}</p>
              <ClearRounded
                style={{cursor:'pointer',color:'red'}}
                onClick={removeExperience}
              />
            </div>
          )
        })}
      </div>
      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
        <ColorButton
          variant="outlined"
          color="primary"
          fullWidth
          onClick={addExperience}
          disabled={disableSubmit}
        >
          add experience
        </ColorButton>
      </div>
    </div>
  );
};

export default AddWorkExperienceComp;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      borderColor: '#0c946e',
      color: '#0c946e',
    },
    backgroundColor: 'white',
    borderColor: '#0FD9A0',
    height: 45,
    color: '#0FD9A0',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 250,
    alignSelf: 'center',
  },
}))(Button);

const years = [
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021'
]