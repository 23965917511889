import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { checkAuthValidity, logout } from '../helpers/authHelpers';
import { ReactComponent as Logo } from '../assets/images/prepschool-logo.svg';

const tabs = [
  { name: 'find_tutor', title: 'Find Tutor', href: '/user/findtutors' },
  { name: 'find_lessons', title: 'Find lessons', href: '/user/findclasses' }
];

const Navigation = (props) => {
  const history = useHistory();
  const [nav, toggleNav] = useState('closed');
  const [ntabs] = useState(props.tabs || tabs);

  const navState = nav === 'closed' ? 'closed' : 'open';

  const renderAuthButton = () => {
    return (
      <div className="nav-buttons">
        {checkAuthValidity()
          ?
            <ColorButton variant="outlined" color="primary" onClick={() => logout(history)}>
              sign out
            </ColorButton>
          : <ColorButton variant="outlined" color="primary" href="/login">
              sign in
            </ColorButton>
        }
        {
          checkAuthValidity()
          ? null
          : <ColorButton2
              variant="contained"
              href="/signup"
            >
              SIGN UP
            </ColorButton2>
        }
      </div>
    );
  }

  return (
    <div
      className={`nav-container ${nav}`}
      //style={props.position ? { position: props.position } : {}}
    >
      <div className="nav-content">
        <div className="nav-control">
          <div className="nav-logo flex flex-ai-ct pointer">
            <Link className="prepschool-link" href="/">
              <Logo />
            </Link>
          </div>
          <div className="nav-cancel"
            onClick={() => nav === 'closed' ? toggleNav('open') : toggleNav('closed')}
          >
            <span>
              {nav === 'closed' 
                ? <MenuIcon style={{color:'#ffffff'}} />
                : <CloseIcon style={{color:'#ffffff'}} />}
            </span>
          </div>
        </div>
        <div className={`flex-cont ${navState}`}>
          <ul className="nav-items">
            {ntabs.map(tab => {
              if (props.currentTab === tab.name) {
                return (
                  <Link key={tab.name} href={tab.href} style={{textDecoration:'none'}}>
                    <li className="current-tab nav-item pointer" key={tab.title}>{tab.title}</li>
                  </Link>
                );
              }
              return (
                <Link key={tab.name} href={tab.href} style={{textDecoration:'none'}}>
                  <li className="nav-item pointer" key={tab.title}>{tab.title}</li>
                </Link>
              );
            })}
          </ul>
          {renderAuthButton()}
        </div>
      </div>
    </div>
  );
}

export default Navigation;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'darkblue',
    },
    backgroundColor: 'inherit',
    borderColor: '#ffffff',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    width: '100%',
    maxWidth: 120,
  },
}))(Button);

const ColorButton2 = withStyles((theme) => ({
  root: {
    '&:hover': {
      background: 'darkblue',
    },
    background: 'transparent linear-gradient(270deg, #487ADD 0%, #2D5DBD 100%) 0% 0% no-repeat padding-box',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    width: '100%',
    maxWidth: 120,
  },
}))(Button);
