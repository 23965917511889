import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button, makeStyles, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import DashboardLayout from '../../components/DashboardLayout';
import { closePageLoader, displayAlert, displayPageLoader } from '../../helpers/popupHelpers';
import { handleArrayInput } from '../../helpers/validation';
import { store } from '../../global-store/popupContext';
import useValidate from '../../custom-hooks/useValidate';

const initialValues = {
  beneficiaries: { value: '', validation: true },
  course: { value: '', validation: true },
};

const HireTutor = (props) => {
  const tutorId = props.match.params.id;
  const classes = useStyles();
  const history =  useHistory();
  
  const { dispatch } = useContext(store);

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [liveCourses, setLiveCourses] = useState([]);
  const [children, setChildren] = useState([]);
  const [childStudents, setChildStudents] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);

  const disableBtn = useValidate(formValues);
  
  const tabs = [
    { name: 'find_tutor', title: 'Find Tutors', href: '/user/findtutors' },
    { name: 'find_lessons', title: 'Find courses', href: '/user/findclasses' },
    { name: 'bookings', title: 'View Bookings', href: '/booked/courses' }
  ];

  useEffect(() => {
    if (user && user.role === 'student') {
      handleArrayInput('beneficiaries', [user.user_id], 1, 20, formValues, setFormValues);
    }
  }, [formValues, user]);

  const fetchTutorLiveCourses = useCallback(() => {
    prepschoolClasses().get('/live-courses', { params: { tutor: tutorId, limit: 100 } })
      .then((data) => {
        setLiveCourses(data.data.data);
      })
      .catch((err) => {
        console.log(err)
      });
  }, [tutorId]);
  
  useEffect(() => { fetchTutorLiveCourses(); }, [fetchTutorLiveCourses]);

  const checkIfChildEnrolled = useCallback((childrenData) => {
    Promise.all(childrenData.map((child) => {
      return prepschoolClasses().get(
        '/students',
        {
          params: { 
            user_id: child.user_id,
            course: props.match.params.course_id,
            type: 'LIVE_COURSE'
          }
        }
      )
    }))
      .then((data) => {
        const temp = data.map(item => item.data.data).flat();
        setChildStudents(temp.map(item => item.user_id));
      })
      .catch();
  }, [props]);

  const fetchChildren = useCallback(() => {
    prepschoolAuth().get('/children')
      .then((data) => {
        setChildren(data.data.data);
        checkIfChildEnrolled(data.data.data);
      })
      .catch();
  }, [checkIfChildEnrolled]);
  
  useEffect(() => { fetchChildren(); }, [fetchChildren]);

  const renderTotalPrice = (course, beneficiaries) => {
    if (course === '') return null;
    if (beneficiaries === '') return null;

    return (
      <p style={{font:'bold 24px/30px Quicksand',color:'#071340',margin:'0 0 30px',width:'100%'}}>
        Total: &#8358;{Number(course[0].price) * beneficiaries.length}
      </p>
    );
  };
  
  const bookTutor = () => {
    displayPageLoader(dispatch);
    const tutor = tutorId;
    const courseObj = formValues.course.value[0];
    const beneficiaries = formValues.beneficiaries.value;
    const children = beneficiaries.map((item) => item.user_id).join(',');
    const course = courseObj.course_id;
    const payload = { tutor, children, course };
    
    prepschoolClasses().post('/bookings/live-courses', payload)
      .then((data) => {
        closePageLoader(dispatch);
        history.push('/booked/courses');
      })
      .catch(({ response, message }) => {
        closePageLoader(dispatch);
        if (response) {
          displayAlert(dispatch, 'error', response.data.error);
          history.push('/booked/courses');
        }
        else displayAlert(dispatch, 'error', message);
      });
  };

  return (
    <DashboardLayout tabs={tabs} parentOrStudentOnly>
      <section className="parent-enroll-child">
        <div className="pec-container-div">
          <div className="pec-cont-div1">Book Live Course</div>
          <div className="pec-cont-div2">
            {
              (user && user.role === 'parent')
                ? <>
                    <Autocomplete
                      style={{marginBottom:20}}
                      multiple
                      fullWidth
                      options={children.filter((item) => !childStudents.includes(item.user_id))}
                      getOptionLabel={(option) => option.first_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select child/children"
                          placeholder="You can select multiple"
                        />
                      )}
                      onChange={(e, value) => {
                        handleArrayInput('beneficiaries', value, 1, 20, formValues, setFormValues);
                      }}
                    />
                    <Button
                      variant="outlined"
                      color="inherit"
                      classes={{root: classes.btnOutlined}}
                      href="/add-a-child?lastpage=/users/enroll-child"
                      fullWidth
                    >
                      add new child
                    </Button>
                  </>
                : null
            }
            <Autocomplete
              style={{marginBottom:20}}
              fullWidth
              options={liveCourses}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select the Subject"
                  //placeholder="You can select multiple"
                  helperText="Select from tutors live courses"
                />
              )}
              onChange={(e, value) => {
                handleArrayInput('course', [value], 1, 20, formValues, setFormValues);
              }}
            />
            {/*<TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="tutor-name-field"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              label="Select from Available days"
              variant="standard"
            />
            <Autocomplete
              style={{marginBottom:20}}
              multiple
              fullWidth
              options={availableHours}
              getOptionLabel={(option) => option.title}
              defaultValue={[availableHours[2]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="For how Long"
                  placeholder="You can select multiple"
                  helperText="Select how long in terms of months"
                />
              )}
            />*/}
            {renderTotalPrice(formValues.course.value, formValues.beneficiaries.value)}
            <ColorButton
              variant="contained"
              color="primary"
              classes={{root: classes.btnContained}}
              fullWidth
              onClick={bookTutor}
              disabled={disableBtn}
            >
              book course
            </ColorButton>
            <p style={{fontSize:14,marginTop:40}}><b>NB:</b> Tutor has to accept your booking before you can pay</p>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default HireTutor;

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#0FD9A0',
    backgroundColor: '#0FD9A0',
    '&:hover': {
      backgroundColor: '#0c946e',
    },
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    }
  },
  btnOutlined: {
    height: 45,
    color: '#0FD9A0',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200,
    marginBottom: 20
  },
  btnContained: {
    height: 45,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}));
