import React from 'react';
import Rating from '@material-ui/lab/Rating';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
//import classImagePlaceholder from '../assets/images/focused-young-woman-and-girl-drawing-together-at-home-4173332.png';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';

const TutorsLiveClassCard = ({ course }) => {
  const classes = useStyles();

  return (
    <div className="online-clses-item">
      <div className="onl-cls-img">
        <img alt="tutor" src={course.poster} />
      </div>
      <div className="onl-cls-content">
        <div className="oci-rating-div">
          <p>{course.title}</p>
          <div className="crd-div">
            <Rating value={3.5} precision={0.5} readOnly />
            <div className="ctc-avatar" title="tutor">
              <img alt="tutor" src={avatarPlaceholder} />
            </div>
          </div>
        </div>
        <p className="oci-mid-p">{course.description}</p>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <ColorButton
            variant="contained"
            color="primary"
            classes={{root: classes.btnRoot}}
            href={'/preview-live-class/' + course.course_id}
          >
            View Course
          </ColorButton>
        </div>
      </div>
    </div>
  );
};

export default TutorsLiveClassCard;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}));
