import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import prepschoolAuth from '../../apis/prepschoolAuth';
import { store } from '../../global-store/popupContext';
import { findRoleDashboard } from '../../helpers/authHelpers';
import { ReactComponent as Logo } from '../../assets/images/prepschool-logo-blue.svg';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 40,
    backgroundColor: 'black',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    marginBottom: 20,
    maxWidth: 200,
  },
}));

function EmailVerificationStatus(props) {
  const classes = useStyles();
  const history = useHistory();
  
  const { dispatch } = useContext(store);

  const queryString = props.location.search.slice(1);
  const queryParams = new URLSearchParams(queryString);

  const token = queryParams.get('token') || '';
  const email = queryParams.get('email') || '';

  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [userRole, setUserRole] = useState('');

  const verifyEmailWithToken = useCallback(() => {
    prepschoolAuth.post('/verification/email', { email, token })
      .then((res) => {
        const { user } = res.data;
        localStorage.setItem('authorization', user.token);
        localStorage.setItem('prepschool_auth', JSON.stringify(user));
        setVerificationStatus('success');
        setUserRole(user.role);
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  }, [email, token, dispatch]);
  
  useEffect(() => { verifyEmailWithToken(); }, [verifyEmailWithToken]);

  function resendVerification() {
    prepschoolAuth.get('/verify/link')
      .then((res) => {

      })
      .catch((err) => {
        
      });
  }

  function onProceedClick() {
    findRoleDashboard(userRole, history);
    window.location.reload();
  }

  if (verificationStatus === 'failed') {
    return verificationFailedComponent(resendVerification, classes);
  } else if (verificationStatus === 'success') {
    return (
      <main className="open-corp-account">
        <div className="opencorpacclogo" style={{paddingLeft:0}}>
          <Link to="/" style={{color:'blue',fontWeight:600,textDecoration:'none'}}>
            <Logo />
          </Link>
        </div>
        <section style={{paddingTop:65}}>
          {/*<CheckCircle />*/}
          <h4 style={{marginBottom:0,marginTop:30}}>Email verified!!</h4>
          <p
            style={{maxWidth: 256,margin:'10px auto',letterSpacing: 0.28,
              color: '#000000',marginBottom:40,}}
          >
            You have successfully verified your email.
          </p>
          <Button
            classes={{root: classes.btnRoot}}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onProceedClick}
          >
            Proceed
          </Button>
        </section>
      </main>
    );
  } else {
    return null;
  }
}

export default EmailVerificationStatus;

const verificationFailedComponent = (resendVerification, classes) => (
  <main className="open-corp-account">
    <div className="opencorpacclogo" style={{paddingLeft:0}}>
      <Link to="/" style={{color:'blue',fontWeight:600,textDecoration:'none'}}>
        PREPSCHOOL
      </Link>
    </div>
    <section style={{paddingTop:65}}>
      {/*<PaymentFailIcon />*/}
      <h4 style={{marginBottom:0,marginTop:30}}>Verification Failed</h4>
      <p
        style={{maxWidth: 256,margin:'10px auto',font:'400 14px/18px Nunito',letterSpacing: 0.28,
          color: '#000000',marginBottom:40,}}
      >
        Click the button below to resend verification email
      </p>
      <Button
        classes={{root: classes.btnRoot}}
        variant="contained"
        color="primary"
        fullWidth
        onClick={resendVerification}
      >
        Resend
      </Button>
    </section>
  </main>
);