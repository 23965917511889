import React, { useCallback, useEffect, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BookIcon from '@material-ui/icons/Book';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PercentageCircle from './percentage-circle/PercentageCircle';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
//import classImagePlaceholder from '../assets/images/focused-young-woman-and-girl-drawing-together-at-home-4173332.png';
import { Link } from '@material-ui/core';
import { prepschoolClasses } from '../apis/prepschoolClasses';

const ClassesTile = ({ record }) => {
  const [course, setCourse] = useState(null);

  const fetchCourse = useCallback(() => {
    prepschoolClasses().get('/courses/' + record.course)
      .then((data) => {
        setCourse(data.data.data);
      })
      .catch();
  }, [record]);

  const fetchLiveCourse = useCallback(() => {
    prepschoolClasses().get('/live-courses/' + record.course)
      .then((data) => {
        setCourse(data.data.data);
      })
      .catch();
  }, [record]);
  
  useEffect(() => {
    record.type === 'LIVE_COURSE' ? fetchLiveCourse() : fetchCourse();
  }, [fetchCourse, fetchLiveCourse, record]);

  const renderProgress = (progress) => {
    PercentageCircle.defaultProps = {
      ...PercentageCircle.defaultProps,
      percentage: progress
    };

    return <PercentageCircle />;
  };

  if (!record || !course) return null;

  return (
    <div className="classes-tile-cont">
      <div className="ctc-image-cont">
        <img alt={course?.title || ""} src={course.poster} />
      </div>
      <div className="ctc-content-cont">
        <p>{({...course}).title}</p>
        <div className="ctc-rating-div">
          <Rating value={3.5} precision={0.5} readOnly />
          <div className="ctc-avatar" title={course?.title}>
            <img alt={course?.title || ""} src={avatarPlaceholder} />
          </div>
        </div>
        <div className="ctc-no-of-cls">
          <span><BookIcon color="inherit" /> 12 Classes</span>
          <span><MenuBookIcon color="inherit" /> 12 Classes</span>
        </div>
        <div className="ctc-last-div">
          {renderProgress(record.progress)}
          <Link
            href={
              record.type === 'LIVE_COURSE'
                ? `/preview-live-class/${record.course}`
                : `/course-arena/${record.course}/0`
            }
            style={{color:'inherit'}}
          >
            <ArrowForwardRoundedIcon color="inherit" className="ctc-forward-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClassesTile;
