import React from 'react';
import Rating from '@material-ui/lab/Rating';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import PercentageCircle from './percentage-circle/PercentageCircle';
import { Link } from '@material-ui/core';

const RegisteredClassesCard = ({ children, childStudents }) => {
  if (!childStudents || !children) return null;

  const renderProgress = (childStudentRecord) => {
    PercentageCircle.defaultProps = {
      ...PercentageCircle.defaultProps,
      percentage: childStudentRecord.progress
    };

    return <PercentageCircle />;
  };

  return (
    <div className="registered-classes-comp">
      <div className="reg-cl-top-div">
        <span>Registered Courses</span>
        <Link href="/user/findclasses" style={{color:'inherit'}}>
          <AddCircleOutlineRoundedIcon color="inherit" />
        </Link>
      </div>
      <div className="reg-cl-list-container">
        {childStudents === 'loading'
          ?
            <p>Loading Progress...</p>
          : 
            childStudents.map((item, idx) => {
              return (
                <div key={idx} className="reg-cl-list-item">
                  <div className="reg-cl-li-div1">
                    <div className="reg-cl-avatar-div">
                      <img src={avatarPlaceholder} alt="prepschool user" />
                    </div>
                    <div className="reg-cl-rating-div">
                      <p>{item.title}</p>
                      <Rating value={3.5} precision={0.5} readOnly />
                    </div>
                  </div>
                  <div className="reg-cl-li-div2">
                    <div className="reg-cl-div2-child-name">
                      <p>{children.find(child => child.user_id === item.user_id).first_name}</p>
                      <b>{children.find(child => child.user_id === item.user_id).last_name}</b>
                    </div>
                    {renderProgress(item)}
                  </div>
                </div>
              )
            })
        }
      </div>
    </div>
  );
};

export default RegisteredClassesCard;
