import React, { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChatIcon from '@material-ui/icons/Chat';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { useHistory } from 'react-router-dom';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import { subjectCategories } from '../helpers/subjectCategories';

const TutorTile = ({ tutor }) => {
  const tutorSubjects = tutor.subjects.split(',');
  const [subjects, setSubjects] = useState(subjectCategories);

  useEffect(() => fetchSubjects(), []);

  const history = new useHistory();

  const fetchSubjects = () => {
    prepschoolClasses().get('/subjects')
      .then((data) => {
        setSubjects(data.data.data);
      })
      .catch(err => console.log(err));
  };

  if (!tutor) return null;

  return (
    <div className="tutor-tile-cont" onClick={() => history.push(`/view-tutor/${tutor.user_id}`)}>
      <div className="ttc-avatar-div">
        <div><img alt={`${tutor.first_name} ${tutor.last_name}`} src={avatarPlaceholder} /></div>
      </div>
      <div className="ttc-content-div">
        <div className="ttc-content-top">
          <div>
            <p style={{textTransform:'capitalize'}}>{tutor.first_name} {tutor.last_name}</p>
            <Rating value={3.5} precision={0.5} readOnly />
          </div>
          <span className="ttc-price">&#8358;{tutor.price}/hour</span>
        </div>
        <div className="ttc-content-mid">
          <ul>
            {tutor
              ? subjects.filter((sub) => {
                  return tutorSubjects.includes(String(sub.id))
                })
                .map((sub, idx) => {
                  return <li key={idx}><div></div>{sub.title}</li>
                })
              : null}
          </ul>
        </div>
        <div className="ttc-content-down">
          <p>{tutor.about}</p>
          <div className="ttc-icon-div">
            <div><BookmarkIcon fontSize="small" color="inherit" /></div>
            <div><ChatIcon fontSize="small" color="inherit" /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorTile;
