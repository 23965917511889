import React from 'react';

export const checkPasswordStrength = (value, setPasswordStrength, setFormValues, formValues) => {
  let pwdStrength = 0;

  if (value.length < 6) return setPasswordStrength(<span>minimum of 6 characters</span>);
  if (value.length > 16) return setPasswordStrength(<span>maximum of 16 characters</span>);
  
  if (/\d+/g.test(value)) pwdStrength += 20;
  if (/[a-z]/g.test(value)) pwdStrength += 20;
  if (/[A-Z]/g.test(value)) pwdStrength += 20;
  if (/[^a-z0-9]/gi.test(value)) pwdStrength += 20;
  if (value.length >= 6) pwdStrength += 20;

  if (pwdStrength < 100) {
    setFormValues({ ...formValues, password: { value, validation: false } });
  }

  switch(pwdStrength) {
    case 0:
      return setPasswordStrength(null);
    case 20:
      return setPasswordStrength(<span style={{color:'red'}}>very weak</span>)
    case 40:
      return setPasswordStrength(<span style={{color:'red'}}>weak</span>)
    case 60:
      return setPasswordStrength(<span style={{color:'orange'}}>fair</span>)
    case 80:
      return setPasswordStrength(<span style={{color:'lightgreen'}}>good</span>)
    case 100:
      return setPasswordStrength(<span style={{color:'darkgreen'}}>excellent</span>)
    default:
      return;
  }
}

export const checkAuthValidity = () => {
  const authToken = localStorage.getItem('authorization');
  if (!authToken || typeof authToken !== 'string') return false;

  const tokenDecoded = JSON.parse(window.atob(authToken.split('.')[1]));
  if (!tokenDecoded) return false;

  if (tokenDecoded.exp > Date.now() / 1000) return true
  else return false;
};

export const logout = (history) => {
  localStorage.clear();
  history.push('/');
};

export const checkEmailVerified = (history) => {
  const userDetail = JSON.parse(localStorage.getItem('prepschool_auth'));

  if (userDetail && !userDetail.email_verified) {
    history.push('/verification/email-sent');
  }
}

export const findRoleDashboard = (role, history) => {
  switch (role) {
    case 'parent':
      history.push('/users/parents/dashboard');
      break;
    case 'tutor':
      history.push('/users/tutors/dashboard');
      break;
    case 'child':
      history.push('/users/children/dashboard');
      break;
    case 'student':
      history.push('/users/students/dashboard');
      break;
    default:
      history.push('/users/students/dashboard');
  }
};

export const ensureTutor = (history) => {
  const user = JSON.parse(localStorage.getItem('prepschool_auth'));
  if (!user) return history.push('/');
  if (user.role !== 'tutor') findRoleDashboard(user.role, history);
};

export const ensureParent = (history) => {
  const user = JSON.parse(localStorage.getItem('prepschool_auth'));
  if (!user) return history.push('/');
  if (user.role !== 'parent') findRoleDashboard(user.role, history);
};

export const ensureChild = (history) => {
  const user = JSON.parse(localStorage.getItem('prepschool_auth'));
  if (!user) return history.push('/');
  if (user.role !== 'child') findRoleDashboard(user.role, history);
};

export const ensureStudent = (history) => {
  const user = JSON.parse(localStorage.getItem('prepschool_auth'));
  if (!user) return history.push('/');
  if (user.role !== 'student') findRoleDashboard(user.role, history);
};

export const ensureParentOrStudent = (history) => {
  const rolesAllowed = ['parent', 'student'];
  const user = JSON.parse(localStorage.getItem('prepschool_auth'));
  //if (!user) return history.push('/');
  if (!rolesAllowed.includes(user.role)) findRoleDashboard(user.role, history);
};

export const ensureChildOrStudent = (history) => {
  const rolesAllowed = ['child', 'student'];
  const user = JSON.parse(localStorage.getItem('prepschool_auth'));
  if (!user) return history.push('/');
  if (!rolesAllowed.includes(user.role)) findRoleDashboard(user.role, history);
};
