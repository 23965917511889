import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button, makeStyles, TextField, withStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { store } from '../../global-store/popupContext';
import PlaceholderImg from '../../assets/images/video-placeholder-1.jpg';
import { handleFileInput, handleFormInput } from '../../helpers/validation';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { closePageLoader, displayAlert,displayPageLoader } from '../../helpers/popupHelpers';
import useValidate from '../../custom-hooks/useValidate';

const initialValues = {
  serial_no: { value: '', validation: true },
  title: { value: '', validation: true },
  video: { value: '', validation: true },
}

const VideoLessonsTab = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [addedClasses, setAddedClasses] = useState([]);
  const [reloadClasses, setReloadClasses] = useState(true);

  const validForm = useValidate(formValues);

  const fetchClasses = useCallback(() => {
    prepschoolClasses().get('/classes/course/' + props.courseId)
      .then(({ data: { data } }) => {
        const sorted = data.sort((a, b) => a.serial_no - b.serial_no);
        setAddedClasses(sorted);
      })
      .catch((err) => {
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  }, [props.courseId, dispatch]);
  
  useEffect(() => { fetchClasses() }, [reloadClasses, fetchClasses]);

  const deleteClass = (classId) => {
    displayPageLoader(dispatch);

    prepschoolClasses().delete('/classes/' + classId)
      .then((data) => {
        const { serial_no } = data.data.data;
        closePageLoader(dispatch);
        displayAlert(dispatch, 'success', 'class with serial no.' + serial_no + ' deleted');
        setReloadClasses(!reloadClasses);
      })
      .catch((err) => {
        closePageLoader(displayPageLoader);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  };

  const submitClass = () => {
    displayPageLoader(dispatch);

    const formData = new FormData();
    formData.append('serial_no', formValues.serial_no.value);
    formData.append('course', props.courseId);
    formData.append('title', formValues.title.value);
    formData.append('video', formValues.video.value);
    formData.append('section', 'section 1');

    prepschoolClasses().post('/classes', formData)
      .then((data) => {
        closePageLoader(dispatch);
        displayAlert(dispatch, 'success', 'class added successfully!!');
        setReloadClasses(!reloadClasses);
        setFormValues(initialValues);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  };

  return (
    <div className="ucpage-div2">
      <h2>Video Lessons</h2>
      <p>Add video that are not too long and help keep the students engaged for the class</p>
      <hr />
      <div className="ucpage-div2-video-div">
        <ul>
          {addedClasses.map((item, idx) => (
            <li key={idx}>
              <img
                onClick={() => history.push(`/preview-class/${item.course}/${item.serial_no - 1}`)}
                src={PlaceholderImg}
                alt="video"
                style={{cursor:'pointer'}}
              />
              <div className="ucp-div2-title-div">
                <div >
                  <h4>{item.serial_no}. {item.title}</h4>
                </div>
                <div>
                  <Delete onClick={() => {
                    dispatch({
                        type: 'DISPLAY_CONFIRM_ACTION_MODAL',
                        payload: { open: true, message: '', action: () => deleteClass(item.class_id) }
                      });
                    }}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div style={{display:'flex',flexWrap:'wrap'}}>
          <TextField
            className="form-fields"
            style={{margin: '0 20px 0 0',minWidth:100}}
            id="serial_no-field"
            label="Serial Number"
            placeholder="Eg. 1"
            variant="standard"
            value={formValues.serial_no.value}
            error={!formValues.serial_no.validation}
            onChange={(e) => handleFormInput('serial_no', e.target.value, /^/gi, formValues, setFormValues)}
          />
          <TextField
            className="form-fields"
            style={{marginBottom:20,flex:1,minWidth:250}}
            id="serial_no-field"
            label="Class Title"
            placeholder="Eg. Intro to basic mathematics"
            variant="standard"
            value={formValues.title.value}
            error={!formValues.title.validation}
            onChange={(e) => handleFormInput('title', e.target.value, /^/gi, formValues, setFormValues)}
          />
        </div>
        <div style={{width:'100%',marginBottom:20}}>
          <div className={formValues.video.validation !== true ? 'custom-file-field-error' : ''}
            style={{display:'flex',alignItems:'center',padding:5,borderRadius:4,border:'1px dashed #00000026'}}
          >
            <div className={classes.root}>
              <input
                className={classes.input}
                id="contained-button-file"
                type="file"
                accept="video/mp4"
                onChange={(e) => {
                  handleFileInput('video', e.target.files[0], 30000000, formValues, setFormValues);
                }}
              />
              <label htmlFor="contained-button-file">
                <Button className={classes.btnUpload} variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
            </div>
            {formValues.video.value !== ''
                ? <span>{formValues.video.value.name }</span>
                : <span>Upload video for this class</span>
            }
          </div>
          <span style={{color:'#e53935'}}>{formValues.video.validation !== true ? formValues.video.validation : ''}</span>
        </div>
        <div className="ucpage-div2-btn-div">
          <DarkBlueButton
            variant="contained"
            color="primary"
            component="span"
            disabled={validForm}
            onClick={submitClass}
          >
            Upload Class
          </DarkBlueButton>
        </div>
      </div>
    </div>
  );
};

export default VideoLessonsTab;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const DarkBlueButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'black',
    },
    backgroundColor: '#071340',
    height: 45,
    color: 'white',
    width: 160,
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
