import React, { useState, useEffect, useCallback } from 'react';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import BookedLiveCourse from '../../components/BookedLiveCourse';
import DashboardLayout from '../../components/DashboardLayout';

const ViewBookedCourses = (props) => {
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [bookings, setBookings] = useState([]);
  const [reload, setReload] = useState(true);

  const tabs = [
    { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
    { name: 'profile', title: 'My Profile', href: '/tutor-profile/' + user.user_id }
  ];

  const fetchBooking = useCallback(() => {
    const params = { tutor: user.user_id };

    prepschoolClasses().get('/bookings/live-courses', { params })
      .then((data) => {
        setBookings(data.data.data);
      })
      .catch(({ message, response }) => {
        console.log('error', message, response);
      })
  }, [user]);

  useEffect(() => { fetchBooking(); }, [reload, fetchBooking]);

  return (
    <DashboardLayout tabs={tabs} tutorsOnly>
      <div className="parents-db-page">
        <section className="parents-db-sec2">
          <h4>Booked Live Online Classes</h4>
          <div>
            {bookings.map((item, idx) => {
              if (!item) return null;
              return (
                <BookedLiveCourse
                  key={idx}
                  booking={item}
                  reload={reload}
                  setReload={setReload}
                />
              );
            })}
          </div>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default ViewBookedCourses;
