import React, { useContext, useEffect } from 'react';
import Navigation from './Navigation';
import {
  checkAuthValidity,
  checkEmailVerified,
  ensureChild,
  ensureChildOrStudent,
  ensureParent,
  ensureParentOrStudent,
  ensureStudent,
  ensureTutor,
} from '../helpers/authHelpers';
import { useHistory } from 'react-router-dom';
import { displayLoginModal } from '../helpers/popupHelpers';
import { store } from '../global-store/popupContext';

const DashboardLayout = (props) => {
  const {
    proceedUnAuthenticated,
    tabs,
    currentTab,
  } = props;
  const history = useHistory();

  const { dispatch } = useContext(store);
  
  useEffect(() => {
    if (proceedUnAuthenticated) return;
    if (!checkAuthValidity()) displayLoginModal(dispatch);
  }, [dispatch, proceedUnAuthenticated]);

  useEffect(() => {
    if (proceedUnAuthenticated) return;
    checkEmailVerified(history);
  }, [proceedUnAuthenticated, dispatch, history]);

  useEffect(() => {
    if (props.parentsOnly) return ensureParent(history);
    if (props.tutorsOnly) return ensureTutor(history);
    if (props.childOnly) return ensureChild(history);
    if (props.studentsOnly) return ensureStudent(history);
    if (props.parentOrStudentOnly) return ensureParentOrStudent(history);
    if (props.childOrStudentOnly) return ensureChildOrStudent(history);
  }, [history, props]);

  return (
    <main className="dsb-layout">
      <div className="dsb-top-ribbon">
        <Navigation tabs={tabs} currentTab={currentTab} />
      </div>
      {props.children}
    </main>
  );
};

export default DashboardLayout;
