import React, { useCallback, useEffect, useState } from 'react';
import { prepschoolClasses } from '../apis/prepschoolClasses';

const ChildrenCard = ({ tutor }) => {
  const [tutorStudents, setTutorStudents] = useState([]);
  const [tutorCourses, setTutorCourses] = useState([]);

  const fetchTutorStudents = useCallback(() => {
    prepschoolClasses().get('/students', { params: { tutor } })
      .then((data) => {
        const courseIds = data.data.data.map((stud) => stud.course);
        const uniqueCourses = courseIds.filter((id, idx) => courseIds.indexOf(id) === idx);
        setTutorStudents(data.data.data);
        setTutorCourses(uniqueCourses);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tutor]);

  useEffect(() => { fetchTutorStudents(); }, [fetchTutorStudents]);

  return (
    <div className="students-card-comp chidren-card-comp">
      <div className="ch-cd-top-div">
        <span>Students</span>
        {/*<AddCircleOutlineRoundedIcon color="inherit" />*/}
        <b>{tutorStudents.length}</b>
      </div>
      <div className="ch-cd-bottom-div">
        {
          tutorCourses.map((courseId) => (
            <div className="child-avatar">
              <div className="ch-av-avatar-div">
                <img
                  src={tutorStudents.find((stud) => stud.course === courseId).poster}
                  alt={tutorStudents.find((stud) => stud.course === courseId).title}
                />
              </div>
              <div
                className="tutor-stud-title-div"
                title={tutorStudents.find((stud) => stud.course === courseId).title}
              >
                <p>{tutorStudents.find((stud) => stud.course === courseId).title}</p>
              </div>
              <span><b>{tutorStudents.filter((stud) => stud.course === courseId).length}</b> student(s)</span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ChildrenCard;
