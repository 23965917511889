import React, { useCallback, useEffect, useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import OnlineClassesCard from '../../components/OnlineClassesCard';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { Link } from 'react-router-dom';
import ClassesTile from '../../components/ClassesTile';
import TutorTile from '../../components/TutorTile';

const tabs = [
  { name: 'find_lessons', title: 'Find Courses', href: '/user/findclasses' },
  { name: 'find_tutor', title: 'Find tutors', href: '/user/findtutors' },
  { name: 'live_course_booking', title: 'View booking', href: '/booked/courses' },
];

const StudentsDashboard = () => {
  const [records, setRecords] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [courses, setCourses] = useState([]);
  const [tutorLiveCourses, setTutorLiveCourses] = useState([]);

  useEffect(() => fetchMyTutors(), []);
  useEffect(() => fetchCourses(), []);
  useEffect(() => fetchTutorLiveCourses(), []);

  const fetchStudentRecord = useCallback(() => {
    if (!user) return;
    setRecords('loading');
    prepschoolClasses().get('/students', { params: { user_id: user.user_id } })
      .then((data) => {
        setRecords(data.data.data);
      })
      .catch((err) => {
        setRecords([]);
        console.log(err);
      });
  }, [user]);
  
  useEffect(() => { fetchStudentRecord(); }, [fetchStudentRecord]);

  const fetchMyTutors = () => {
    setTutors('loading');
    prepschoolAuth().get('/tutors')
      .then((data) => {
        setTutors(data.data.data);
      })
      .catch((err) => {
        setTutors([]);
      });
  };

  const fetchCourses = () => {
    setCourses('loading');
    prepschoolClasses().get('/courses', { params: { limit: 3 } })
      .then((data) => {
        setCourses(data.data.data);
      })
      .catch((err) => {
        setCourses([]);
      });
  };

  const fetchTutorLiveCourses = () => {
    setTutorLiveCourses('loading');
    prepschoolClasses().get('/live-courses', { params: { status: 'published' } })
      .then((data) => {
        setTutorLiveCourses(data.data.data);
      })
      .catch((err) => {
        setTutorLiveCourses([]);
        console.log(err)
      });
  };

  return (
    <DashboardLayout tabs={tabs} currentTab="parents_dashboard" studentsOnly>
      <div className="parents-db-page tutors-dashboard">
        {records[0]
          ?
            <section className="classes-page-sec tutors-page-sec">
              <h4>My Subcribed Courses</h4>
              <div className="classes-found-cont" style={{justifyContent:'flex-start'}}>
                {records === 'loading'
                  ? <p>Courses loading...</p>
                  : records.map((item, idx) => {
                      return (<ClassesTile key={idx} record={item} />);
                    })
                }
              </div>
            </section>
          : null
        }
        {courses[0]
          ?
            <section className="parents-db-sec2">
              <h4>
                <Link
                  style={{color:'inherit',textDecoration:'inherit'}}
                  to="/user/findclasses"
                >Available Video Courses</Link>
              </h4>
              <div className="online-clses-cont">
                {courses === 'loading'
                  ? <p>Courses loading...</p>
                  : courses.map((item, idx) => (
                      <OnlineClassesCard course={item} key={idx} />
                    ))
                }
              </div>
            </section>
          : null
        }
        {tutorLiveCourses[0]
          ?
            <section className="parents-db-sec2">
              <h4>
                <Link
                  style={{color:'inherit',textDecoration:'inherit'}}
                  to="/user/live-courses"
                >Available Live Courses</Link>
              </h4>
              <div className="online-clses-cont">
                {tutorLiveCourses === 'loading'
                  ? <p>Live courses loading...</p>
                  : tutorLiveCourses.map((item, idx) => (
                      <OnlineClassesCard
                        key={idx}
                        course={item}
                        nextPage={`/hire-tutor/${item.tutor}`}
                        //action={() => history.push(`/tutor-profile/${tutor.user_id}`)}
                      />
                    ))
                }
              </div>
            </section>
          : null
        }
        {tutors[0]
          ?
            <section className="tutors-page-sec section-sizing classes-page-sec">
              <h4>
                <Link
                  style={{color:'inherit',textDecoration:'inherit'}}
                  to="/user/findtutors"
                >Available Tutors</Link>
              </h4>
              <div className="classes-found-cont">
              {tutors === 'loading'
                ? <p>Tutors loading...</p>
                : tutors.map((item, idx) => {
                    return (<TutorTile tutor={item} key={idx} />);
                  })
              }
              </div>
            </section>
          : null
        }
      </div>
    </DashboardLayout>
  );
};

export default StudentsDashboard;
