import React from 'react';

const StepsIndicator = () => {
  return (
    <div className="steps-indicator">
      <div className="sti-circle">1</div>
      <div className="sti-line-blue sti-line"></div>
      <div className="sti-circle">2</div>
      <div className="sti-line"></div>
      <div className="sti-circle-gray sti-circle">3</div>
      <div className="sti-line"></div>
      <div className="sti-circle-gray sti-circle">4</div>
    </div>
  );
};

export default StepsIndicator;
