import React, { useCallback, useContext, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DashboardLayout from '../../components/DashboardLayout';
import { store } from '../../global-store/popupContext';
import {
  displayAlert,
  openConfirmDialog,
} from '../../helpers/popupHelpers';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { useHistory } from 'react-router-dom';
import { initiateCoursePayment } from '../../helpers/payments';
import { paymentRedirect } from '../../helpers/messages';
import { handleArrayInput, validate } from '../../helpers/validation';

const initialValues = {
  child: { value: '', validation: true },
};

const ParentEnrollChild = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { dispatch } = useContext(store);

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [course, setCourse] = useState(null);
  const [coursePublished, setCoursePublished] = useState(false);
  const [children, setChildren] = useState([]);
  const [childStudents, setChildStudents] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [fadeBtn, setFadeBtn] = useState(true);
  
  //useEffect(() => checkIfChildEnrolled(), []);
  useEffect(() => validate(formValues, setFadeBtn), [formValues]);

  const checkCoursePublished = useCallback(() => {
    prepschoolClasses().get(`/courses/${props.match.params.course_id}`)
      .then((data) => {
        setCourse(data.data.data);
        const { status } = data.data.data;
        status === 'published' ? setCoursePublished(true) : setCoursePublished(false);
      })
      .catch((err) => {
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', err.message);
      });
  }, [props.match.params.course_id, dispatch]);
  
  useEffect(() => { checkCoursePublished(); }, [checkCoursePublished]);

  const checkIfChildEnrolled = useCallback((childrenData) => {
    Promise.all(childrenData.map((child) => {
      return prepschoolClasses().get(
        '/students',
        { params: { user_id: child.user_id, course: props.match.params.course_id } }
      )
    }))
      .then((data) => {
        const temp = data.map(item => item.data.data).flat();
        setChildStudents(temp.map(item => item.user_id));
      })
      .catch();
  }, [props.match.params.course_id]);
  
  const fetchChildren = useCallback(() => {
    prepschoolAuth().get('/children')
      .then((data) => {
        if (!data.data.data.length) {
          return history.push(`/users/enroll-child/${props.match.params.course_id}`);
        }
        setChildren(data.data.data);
        checkIfChildEnrolled(data.data.data);
      })
      .catch();
  }, [history, checkIfChildEnrolled, props.match.params.course_id]);
  
  useEffect(() => { fetchChildren(); }, [fetchChildren]);

  if (!coursePublished) return null;

  return (
    <DashboardLayout proceedUnAuthenticated>
      <section className="parent-enroll-child">
        <div className="pec-container-div">
          <div className="pec-cont-div1">Enroll</div>
          <div className="pec-cont-div2">
            {children[0]
              ? <Autocomplete
                  style={{marginBottom:50}}
                  multiple
                  fullWidth
                  options={children.filter((item) => !childStudents.includes(item.user_id))}
                  getOptionLabel={(option) => `${option.first_name}`}
                  //defaultValue={[children.filter((item) => !childStudents.includes(item.user_id))[0]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select child/children"
                      placeholder="You can select multiple"
                    />
                  )}
                  onChange={(e, value) => {
                    handleArrayInput('child', value, 1, 100, formValues, setFormValues);
                  }}
                />
              : null
            }
            <Button
              variant="outlined"
              color="inherit"
              classes={{root: classes.btnOutlined}}
              href="/add-a-child?lastpage=/users/enroll-child"
              fullWidth
            >
              add new child
            </Button>
            <ColorButton
              variant="contained"
              color="primary"
              classes={{root: classes.btnContained}}
              onClick={() => {
                openConfirmDialog(
                  dispatch,
                  () => initiateCoursePayment(course, formValues.child.value, user, dispatch),
                  paymentRedirect
                );
              }}
              fullWidth
              disabled={fadeBtn}
            >
              proceed to pay
            </ColorButton>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default ParentEnrollChild;

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#0FD9A0',
    backgroundColor: '#0FD9A0',
    '&:hover': {
      backgroundColor: '#0c946e',
    },
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    }
  },
  btnOutlined: {
    height: 45,
    color: '#0FD9A0',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200,
    marginBottom: 30
  },
  btnContained: {
    height: 45,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}));
