import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import { initiateCoursePayment } from '../helpers/payments';
import { store } from '../global-store/popupContext';
import { displayAlert, openConfirmDialog } from '../helpers/popupHelpers';
import { paymentRedirect } from '../helpers/messages';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import { prepschoolAuth } from '../apis/prepschoolAuth';

const BookedLiveCourse = ({ booking, reload, setReload }) => {
  const classes = useStyles();
  
  const { id, children, booking_status } = booking;
  const noOfChildren = children.split(',').length;

  const { dispatch } = useContext(store);

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [childrenNames, setChildrenNames] = useState([]);
  const [childrenObj, setChildrenObj] = useState([]);

  /*
  const fetchTutor = () => {
    prepschoolAuth().get(`/tutors/${tutor}`)
      .then((data) => {
        setTutorObj(data.data.data);
      })
      .catch();
  };*/

  const fetchChildren = useCallback(() => {
    prepschoolAuth().get('/children')
      .then((data) => {
        const temp = data.data.data.filter((item) => children.includes(item.user_id));
        console.log('temp', temp)
        const names = temp.map(item => item.first_name);
        setChildrenNames(names);
        setChildrenObj(temp);
      })
      .catch()
  }, [children]);
  
  useEffect(() => { fetchChildren(); }, [fetchChildren]);

  const acceptCourseBooking = (booking_id, status) => {
    prepschoolClasses().patch(`/bookings/live-courses/${booking_id}`, { status })
      .then(() => {
        displayAlert(dispatch, 'success', 'updated successfully');
        setReload(!reload);
      })
      .catch();
  };

  const chooseColor = (status) => {
    let style = {};
    if (status === 'pending') style.color = 'orange';
    if (status === 'accepted') style.color = 'green';
    if (status === 'rejected') style.color = 'red';

    return style;
  };
  
  const renderActionButton = (status) => {
    if (status === 'pending' || status === 'rejected' || status === 'paid') {
      return <Button variant="contained" disabled>pay for course</Button>;
    }
    return (
      <ColorButton
        variant="contained"
        color="primary"
        fullWidth
        classes={{root: classes.btnContained}}
        onClick={() => {
          openConfirmDialog(
            dispatch,
            () => initiateCoursePayment(
              booking,
              childrenObj,
              user,
              dispatch,
              'LIVE_COURSE'
            ),
            paymentRedirect
          );
        }}
      >
        pay for course
      </ColorButton>
    )
  };

  const renderReviewButton = (id) => {
    return (
      <>
        <ColorButton
          variant="contained"
          color="primary"
          fullWidth
          style={{marginRight:15}}
          classes={{root: classes.btnAccept}}
          onClick={() => acceptCourseBooking(id, 'accepted')}
          disabled={booking_status === 'paid' || booking_status === 'accepted'}
        >
          accept
        </ColorButton>
        <RejectButton
          variant="contained"
          color="primary"
          fullWidth
          classes={{root: classes.btnReject}}
          onClick={() => acceptCourseBooking(id, 'rejected')}
          disabled={booking_status === 'paid' || booking_status === 'rejected'}
        >
          reject
        </RejectButton>
      </>
    );
  };

  return (
    <div className="booked-live-course tutor-tile-cont" >
      <div className="blc-img-div">
        <img src={booking ? booking.poster : null} alt="prepschool live courses" />
      </div>
      <div className="blc-detail-div">
        <h3>{booking ? booking.title : null}</h3>
        <p>Booking status: <b style={chooseColor(booking_status)}>{booking_status}</b></p>
        {booking_status === 'rejected'
          ? <p>Rejection reason: <b style={chooseColor(booking_status)}>course is not available at this time</b></p>
          : null}
        {user.role === 'tutor'
          ? <p>children: <b style={{color:'darkblue'}}>{noOfChildren}</b></p>
          : <p>children: <b style={{color:'darkblue'}}>{childrenNames.join(', ')}</b></p>}
        <p>Total price: <b style={{color:'darkblue'}}>{noOfChildren * Number(booking.price)}</b></p>
        <div>
          {user.role === 'tutor' ? renderReviewButton(id) : renderActionButton(booking_status)}
        </div>
        {booking_status === 'pending' && user.role !== 'tutor'
          ? <p style={{fontSize:13,letterSpacing:1}}><b>NB:</b> Please be patient, the tutor is yet to accept this booking. Once accepted you will be able to pay.</p>
          : null}
      </div>  
    </div>
  )
}

export default BookedLiveCourse;

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#0FD9A0',
    backgroundColor: '#0FD9A0',
    '&:hover': {
      backgroundColor: '#0c946e',
    },
  },
}))(Button);

const RejectButton = withStyles((theme) => ({
  root: {
    color: '#0FD9A0',
    backgroundColor: '#0FD9A0',
    '&:hover': {
      backgroundColor: '#8b3845',
    },
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    }
  },
  btnOutlined: {
    height: 45,
    color: '#0FD9A0',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200,
    marginBottom: 20
  },
  btnContained: {
    height: 45,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
  btnAccept: {
    height: 45,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 150
  },
  btnReject: {
    height: 45,
    backgroundColor: '#c34f61',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 150
  }
}));
