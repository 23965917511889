import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button, Link, withStyles } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { store } from '../../global-store/popupContext';
import DashboardLayout from '../../components/DashboardLayout';
import VideoLessonsTab from '../../components/upload-classes-tabs/VideoLessonsTab';
import ClassInfoTab from '../../components/upload-classes-tabs/ClassInfoTab';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { closePageLoader, displayPageLoader, displayAlert } from '../../helpers/popupHelpers';

const PreviewClass = (props) => {
  const history = useHistory();
  const { dispatch } = useContext(store);

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [comp, setComp] = useState(1);
  const [course, setCourse] = useState(null);

  const tabs = [
    { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
    { name: 'profile', title: 'My Profile', href: '/tutor-profile/' + user.user_id },
    { name: 'bookings', title: 'View Bookings', href: '/tutor/bookings/accept' }
  ];

  const renderComponent = () => {
    if (comp === 1 && course) return <ClassInfoTab course={course} setCourse={setCourse} />;
    if (comp === 2) return <VideoLessonsTab courseId={props.match.params.id} />;
  };

  const fetchCourse = useCallback(() => {
    displayPageLoader(dispatch);
    prepschoolClasses().get('/courses/' + props.match.params.id)
      .then((data) => {
        closePageLoader(dispatch);
        localStorage.setItem('prepschool_course', JSON.stringify(data.data.data));
        setCourse(data.data.data);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        history.push('/upload-classes');
      });
  }, [dispatch, props, history]);
  
  useEffect(() => {
    if (!props.match.params.id) return localStorage.removeItem('prepschool_course');
    if (props.match.params.id) fetchCourse();
    if (course) setComp(2);
  }, [props, course, fetchCourse]);

  const publishCourse = () => {
    displayPageLoader(dispatch);
    prepschoolClasses().patch(`/courses/${props.match.params.id}`, { status: 'published' })
      .then((data) => {
        closePageLoader(dispatch);
        setCourse(data.data.data);
        displayAlert(dispatch, 'success', 'Course published!!!')
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  }

  return (
    <DashboardLayout tabs={tabs} tutorsOnly>
      <section className="upload-classes-page">
        <div className="ucpage-top-div">
          <h1>Upload classes</h1>
          <div>
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch({
                  type: 'DISPLAY_CONFIRM_ACTION_MODAL',
                  payload: { open: true, message: '', action: publishCourse }
                });
              }}
              disabled={!props.match.params.id}
            >
              publish
            </ColorButton>
          </div>
          <p>
            <Link color="inherit" href={'/preview-class/' + props.match.params.id + '/0'}>
              <RemoveRedEye /> Preview class
            </Link>
          </p>
        </div>
        <div className="ucpage-bottom-div">
          <div className="ucpage-sidebar">
            <h3>Edit</h3>
            <hr />
            <ul>
              <li className={comp === 1 ? 'li-on-focus' : ''} onClick={() => setComp(1)}>Course Info</li>
              <li className={comp === 2 ? 'li-on-focus' : ''} onClick={() => setComp(2)}>Video Lessons</li>
            </ul>
          </div>
          {!props.match.params.id ? <ClassInfoTab setComp={setComp} /> : renderComponent()}
        </div>
      </section>
    </DashboardLayout>
  );
};

export default PreviewClass;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    width: 160,
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
