import React, { useCallback, useContext, useEffect, useState } from 'react';
import MUIRichTextEditor from "mui-rte";
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { store } from '../../global-store/popupContext';
import { closePageLoader, displayPageLoader, displayAlert } from '../../helpers/popupHelpers';

const PreviewClass = (props) => {
  const history = useHistory();
  const course_id = props.match.params.id;

  const { dispatch } = useContext(store);

  const [currentTab, setCurrentTab] = useState('class_room');
  const [classes, setClasses] = useState([]);
  const [course, setCourse] = useState(null);
  const [currentVideo] = useState(props.match.params.index);

  const save = (data) => {
    console.log(JSON.parse(data));
  };

  const goToNextVideo = (idx) => {
    if (Number(idx) > classes.length - 1) return;
    history.push(`/preview-class/${course_id}/${idx}`);
    window.location.reload();
  };

  const fetchCourse = useCallback(() => {
    if (course) return null;
    prepschoolClasses().get('/courses/' + course_id)
      .then((data) => {
        setCourse(data.data.data);
      })
      .catch();
  }, [course, course_id]);
  
  useEffect(() => { fetchCourse(); }, [fetchCourse]);

  const renderVideo = () => {
    if (classes[currentVideo]) {
      const fullUrl = classes[currentVideo].video;
      
      return (
        <video controlsList="nodownload" controls autoPlay
          onEnded={() => goToNextVideo(Number(currentVideo) + 1)}
        >
          <source src={fullUrl} type="video/mp4" />
          <source src={fullUrl} type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      );
    }
    return null
  };

  const fetchMetaData = useCallback(() => {
    displayPageLoader(dispatch);

    prepschoolClasses().get('/classes/course/' + course_id)
      .then((data) => {
        closePageLoader(dispatch);
        const sorted = data.data.data.sort((a, b) => a.serial_no - b.serial_no);
        setClasses(sorted);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  }, [dispatch, course_id]);
  
  useEffect(() => { fetchMetaData(); }, [fetchMetaData]);

  const tabs = [
    { id: 'class_room', title: 'Class room' },
    { id: 'notes', title: 'Notes' },
    { id: 'chats', title: 'Chats' },
  ];

  if (!course) return null;

  return (
    <DashboardLayout tutorsOnly>
      <div className="course-arena">
        <div className="co-arena-top-div">
          <div className="top-div-div1">
            <h1>{course.title}</h1>
            {/*<p>Learn how to draw anything and become a master in creating wonderful images.</p>*/}
          </div>
          <div className="top-div-div3">
            <p><Link href={'/update-classes/' + props.match.params.id}>Back to editing</Link></p>
          </div>
          <div className="top-div-div2">
            {tabs.map((item, idx) => (
              item.id === currentTab 
                ? <p className="tdd-current-tab" key={idx}>{item.title}</p>
                : <p key={idx} onClick={() => setCurrentTab(item.id)}>{item.title}</p>
            ))}
          </div>
        </div>
        <div className="co-arena-bottom-div">
          {currentTab === 'class_room' ? renderVideo() : null}
          {currentTab === 'notes'
            ? <div className="co-ar-bt-editor">
                <MUIRichTextEditor
                  label="Type something here..."
                  onSave={save}
                  inlineToolbar={true}
                />
              </div>
            : null}
          <div className="co-ar-bt-div-item">
            <h3>Lesson overview</h3>
            <ul>
              {classes.map((item, idx) => (
                <li key={idx} className={Number(currentVideo) === idx ? 'video-tab-playing' : ''}
                  onClick={() => goToNextVideo(idx)}
                >
                  <div>
                    <input type="checkbox" checked={false} />
                    {Number(currentVideo) === idx
                      ? <p><b>{item.serial_no}. {item.title}</b></p>
                      : <p>{item.serial_no}. {item.title}</p>
                    }
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default PreviewClass;
