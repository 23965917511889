import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { store } from '../../global-store/popupContext';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import prepschoolAuth from '../../apis/prepschoolAuth';
import history from '../../history';
import { closeLoginModal } from '../../helpers/popupHelpers';

const initialValues = {
  email: { value: '', validation: true },
  password: { value: '', validation: true },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginModal() {
  const classes = useStyles();
  const { state, dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [fadeBtn, setFadeBtn] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success] = React.useState(true);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleClose = () => {
    localStorage.clear();
    history.push('/');
    dispatch({ type: 'DISPLAY_LOGIN_MODAL', payload: { open: false } });
  };

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
  }

  const validate = useCallback(() => {
    const valid = Object.values(formValues).every((value) => (
      value.value !== '' && value.validation
    ));

    setFadeBtn(!valid);
  }, [formValues]);
  
  useEffect(() => { validate(); }, [formValues, validate]);

  const submitForm = () => {
    setLoading(true);
    setFadeBtn(true);
    const signinForm = {
      email: formValues.email.value,
      password: formValues.password.value
    };
    prepschoolAuth.post('/signin', signinForm)
      .then((data) => {
        closeLoginModal(dispatch);
        dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 6000, message: 'login successful' } });
        localStorage.setItem('prepschool_auth', JSON.stringify(data.data.user));
        localStorage.setItem('authorization', data.data.user.token);
      })
      .catch((err) => {
        setLoading(false);
        setFadeBtn(false);
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  };

  return (
    <div>
      <Dialog
        open={state.loginModal.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Login to access this page</DialogTitle>
        <DialogContent>
          <form>
            <TextField
            className="form-fields"
            style={{marginBottom: 20}}
            id="email-field"
            fullWidth
            label="Email"
            variant="standard"
            value={formValues.email.value}
            error={!formValues.email.validation}
            onChange={(e) => handleFormInput('email', e.target.value, /./gi)}
            />
            <TextField
            className="form-fields"
            id="password-field"
            style={{marginBottom:40}}
            type="password"
            label="Password"
            placeholder="eg 1d#8%sd232Yds$"
            variant="standard"
            fullWidth
            value={formValues.password.value}
            error={!formValues.password.validation}
            onChange={(e) => handleFormInput('password', e.target.value, /./gi)}
            />
          </form>
        </DialogContent>
        <div style={{margin:'0 auto 20px',width:170,display:'flex',justifyContent:'space-between'}}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={fadeBtn}
              onClick={submitForm}
            >
              login
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
          <Button onClick={handleClose} href="/" variant="outlined" color="secondary">
            cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    '&:hover': {
      backgroundColor: 'darkblue',
    },
  },
  buttonProgress: {
    color: '#3f51b5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
