import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import prepschoolAuth from '../../apis/prepschoolAuth';
import { TextField } from '@material-ui/core';
import { store } from '../../global-store/popupContext';
import { checkPasswordStrength } from '../../helpers/authHelpers';
import { ReactComponent as Logo } from '../../assets/images/prepschool-logo-blue.svg';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 40,
    backgroundColor: 'black',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    marginBottom: 20,
    maxWidth: 200,
  },
}));

const initialValues = {
  password: { value: '', validation: true },
  confirmPassword: { value: '', validation: true },
}

function ResetForgotPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useContext(store);
  
  const queryString = props.location.search.slice(1);
  const queryParams = new URLSearchParams(queryString);

  const token = queryParams.get('token') || '';
  const email = queryParams.get('email') || '';

  if (queryString) {
    localStorage.setItem('forgot_password_email', email);
    localStorage.setItem('forgot_password_token', token);
    history.push('/forgot-password/change');
  }

  const [formValues, setFormValues] = useState(initialValues);
  const [fadeBtn, setFadeBtn] = useState(true);
  const [showPassword] = useState(false);
  const [showConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);

  const handlePasswordInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
    checkPasswordStrength(value, setPasswordStrength, setFormValues, formValues);
  }

  const handleConfirmPassword = (value) => {
    const formTemp = {...formValues};

    formTemp['confirmPassword'] = { value, validation: value === formValues.password.value };

    setFormValues(formTemp);
  }

  const validate = useCallback(() => {
    const valid = Object.values(formValues).every((value) => (
      value.value !== '' && value.validation
    ));

    setFadeBtn(!valid);
  }, [formValues]);
  
  useEffect(() => {
    validate();
  }, [formValues, validate]);
  
  function changePassword() {
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});
    const payload = {
      email: localStorage.getItem('forgot_password_email'),
      token: localStorage.getItem('forgot_password_token'),
      newPassword: formValues.password.value,
    };
    
    prepschoolAuth.post('/forgot-password-change', payload)
      .then(() => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 15000, message: 'Password change successful' } });
        localStorage.clear();
        history.push('/login');
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        console.log(err.message)
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  }

  return (
    <main className="open-corp-account">
      <div className="opencorpacclogo">
        <Link to="/" style={{color:'blue',fontWeight:600,textDecoration:'none'}}>
          <Logo />
        </Link>
      </div>
      <section>
        <h4 style={{marginBottom:0,marginTop:30}}>Change Forgot Password</h4>
        <p style={{maxWidth: 256,margin:'10px auto',color: '#000000',marginBottom:40}}>
          Enter a password of your choice
        </p>
        <TextField
          className="bottom-cl38"
          id="entrepreneur-password-field"
          type={showPassword ? 'text' : 'password'}
          label="password"
          placeholder="eg 1d#8%sd232Yds$"
          variant="outlined"
          fullWidth
          style={{maxWidth:300, marginBottom:20}}
          autoFocus
          value={formValues.password.value}
          helperText={passwordStrength}
          onChange={(e) => handlePasswordInput('password', e.target.value, /^.{8,16}$/gi)}
          error={!formValues.password.validation}
        />
        <TextField
          id="entrepreneur-confirmpassword-field"
          type={showConfirmPassword ? 'text' : 'password'}
          label="confirm password"
          variant="outlined"
          fullWidth
          style={{maxWidth:300, marginBottom:40}}
          value={formValues.confirmPassword.value}
          onChange={(e) => handleConfirmPassword(e.target.value)}
          error={!formValues.confirmPassword.validation}
        />
        <div>
          <Button
            classes={{root: classes.btnRoot}}
            variant="contained"
            color="primary"
            fullWidth
            onClick={changePassword}
            disabled={fadeBtn}
          >
            Reset Password
          </Button>
        </div>
      </section>
    </main>
  );
}

export default ResetForgotPassword;
