const axios = require('axios');

let token = localStorage.getItem("authorization");

if (!token) token = '';

const baseURL = process.env.REACT_APP_AUTH_SERVICE;//"http://localhost:8080/api/v1";
//const baseURL = "https://prepschool-auth.herokuapp.com/api/v1";

const prepschoolAuthApi = axios.create({
  baseURL,
  headers: { Authorization: "Bearer " + token }
});

export const prepschoolAuth = () => {
  const authToken = localStorage.getItem('authorization');

  return axios.create({
    baseURL,
    headers: { Authorization: "Bearer " + authToken }
  });
};

export default prepschoolAuthApi;
