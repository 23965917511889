import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { store } from '../../global-store/popupContext';
import { ReactComponent as Logo } from '../../assets/images/prepschool-logo-blue.svg';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    fontSize: 14,
    marginBottom: 20,
    textDecoration: 'underline',
    font: 'Regular 18px/26px Nunito',
    color: '#6DACFF',
    textTransform: 'capitalize',
    width: 'fit-content',
  },
}));

function EmailVerificaitionSent(props) {
  const classes = useStyles();
  const history = useHistory();

  const { dispatch } = useContext(store);

  const userDetail = JSON.parse(localStorage.getItem('prepschool_auth'));

  useEffect(() => {
    if (!userDetail) return history.push('/login');
    if (userDetail.isVerified) {
      props.displayAlert({ open: true, type: 'error', duration: 2000, message: 'user already verified' });
      return history.push('/users/dashboard');
    }
  }, [userDetail, history, props]);
  
  function resendVerification() {
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});

    prepschoolAuth().post('/verification/email/resend', { email: userDetail.email })
      .then(() => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        dispatch({
          type: 'DISPLAY_ALERT',
          payload: { open: true, type: 'success', duration: 6000, message: 'A new verification email has been sent to your inbox' }
        });
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  }
  
  const backToLandingPage = () => {
    localStorage.clear();
    history.push('/');
  };

  return (
    <main className="open-corp-account">
      <div className="opencorpacclogo">
        <Link onClick={backToLandingPage} style={{color:'blue',fontWeight:600,textDecoration:'none'}}>
          <Logo />
        </Link>
      </div>
      <section>
        <h4 style={{marginBottom:0,marginTop:30}}>Please Verify your Email</h4>
        <p style={{maxWidth: 256,margin:'10px auto',color: '#000000',marginBottom:40}}>
          A mail has been sent to your email with a link to verify your email
        </p>
        <Button
          classes={{root: classes.btnRoot}}
          color="primary"
          fullWidth
          onClick={resendVerification}
        >
          Resend Link
        </Button>
      </section>
    </main>
  );
}

export default EmailVerificaitionSent;
