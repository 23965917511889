import React, { useState, useEffect, useContext, useCallback } from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import { closePageLoader, displayPageLoader, displayAlert } from '../helpers/popupHelpers';
import { store } from '../global-store/popupContext';
import { Button } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

const PaymentSuccessPage = (props) => {
  const urlQuery = new URLSearchParams(props.location.search);
  const status = urlQuery.get('status');
  const transaction_id = urlQuery.get('transaction_id');
  
  const { dispatch } = useContext(store);

  const [context, setContext] = useState('');
  const [paymentCategory, setPaymentCategory] = useState('');
  const [verified, setVerified] = useState(null);
  
  const verifyPayment = useCallback(() => {
    displayPageLoader(dispatch);
    prepschoolClasses().get(`/payments/verify/${transaction_id}`)
      .then((data) => {
        closePageLoader(dispatch);
        setContext(data.data.data.context);
        setPaymentCategory(data.data.data.title);
        setVerified(true);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        setVerified(false);
        displayAlert(dispatch, 'error', err.response.data.error);
        console.log(err.response);
      });
  }, [dispatch, transaction_id]);
  
  useEffect(() => { verifyPayment(); }, [verifyPayment]);

  const renderSuccess = () => {
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <div>
          <h1 style={{display:'flex',alignItems:'center',textTransform:'capitalize',color:'green',marginTop:0}}>
            payment for course {status}!!! <CheckCircleOutlineRoundedIcon fontSize="large" />
          </h1>
          <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
            <Button
              variant="contained"
              color="primary"
              href={
                paymentCategory === 'LIVE_COURSE' ? `/preview-live-class/${context}` : `/course-arena/${context}/0`
              }
            >Proceed</Button>
          </div>
        </div>
      </div>
    );
  };

  const renderFailed = () => {
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <div>
          <h1 style={{display:'flex',alignItems:'center',textTransform:'capitalize',color:'red',marginTop:0}}>
            payment for course failed!!! <Cancel fontSize="large" />
          </h1>
          <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
            <Button
              variant="contained"
              color="primary"
              href="/"
            >Back to dashboard</Button>
          </div>
        </div>
      </div>
    )
  }

  if (verified === null) return null;

  if (verified) return (renderSuccess());

  if (verified === false) return (renderFailed());
};

export default PaymentSuccessPage;
