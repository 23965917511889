import React from 'react';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
// import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { Avatar, Link } from '@material-ui/core';

const ChildrenCard = ({ children }) => {
  if (!children) return null;

  return (
    <div className="chidren-card-comp">
      <div className="ch-cd-top-div">
        <span>Your Children/Wards</span>
        <Link href="/add-a-child" style={{color: '#0FD9A0'}}>
          <AddCircleOutlineRoundedIcon color="inherit" />
        </Link>
      </div>
      <div className="ch-cd-bottom-div">
        {children === 'loading'
            ?
              <p>Loading children...</p>
            : 
              children.map((child, idx) => (
                <div key={idx} className="child-avatar">
                  <Avatar variant="rounded" style={{width:80,height:80}} />
                  {/*<div className="ch-av-avatar-div">
                    <img src={avatarPlaceholder} alt="parents child" />
                  </div>*/}
                  <p>{child.first_name}</p>
                  <span>SS1</span>
                </div>
              ))
        }
      </div>
    </div>
  );
};

export default ChildrenCard;
