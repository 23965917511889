import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { store } from '../../global-store/popupContext';

function TwoWayDialog() {
  const { state, dispatch } = useContext(store);
  
  const { title, message, btn1, btn2, action1, action2 } = state.twoWayDialog;

  const handleClose = () => {
    dispatch({ type: 'DISPLAY_TWO_WAY_DIALOG', payload: { open: false } });
  };

  const handleAction = (action) => {
    action();
    handleClose();
  };

  const titleStyle = {
    fontSize: 18,
    width: '100%',
    textAlign: 'center',
    margin: 0,
    fontWeight: 'bolder',
    color: 'darkblue',
    textTransform: 'uppercase'
  };

  return (
    <Dialog
      maxWidth="xs"
      open={state.twoWayDialog.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <p style={titleStyle}>{title}</p>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{display:'flex',justifyContent:'center',marginBottom:20}}>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={() => handleAction(action1)}
          style={{margin:'0 10px'}}
        >
          {btn1}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={() => handleAction(action2)}
          style={{margin:'0 10px'}}
        >
          {btn2}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TwoWayDialog;
