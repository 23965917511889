import React from 'react';
import SchoolIcon from '@material-ui/icons/School';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DashboardLayout from '../../components/DashboardLayout';

const TutorWelcomePage = () => {
  return (
    <DashboardLayout>
      <div className="tutor-welcome-page">
        <h2>Welcome Ademola!</h2>
        <div className="twp-flex-cont">
          <div className="twp-flex-item">
            <SchoolIcon fontSize="large" />
            <h3>Upload classes</h3>
            <ColorButton
              variant="contained"
              color="primary"
              // onClick={submitForm}
              // disabled={fadeBtn}
              href="/upload-classes"
            >
              add class
            </ColorButton>
          </div>
          <div className="twp-flex-item">
            <SchoolIcon fontSize="large" />
            <div style={{position:'relative'}}>
              <PhotoCameraOutlinedIcon style={{position:'absolute',top:-40,left:10,fontSize:40}} />
            </div>
            <h3>Start a live class</h3>
            <ColorButton
              variant="contained"
              color="primary"
              // onClick={submitForm}
              // disabled={fadeBtn}
              
            >
              go live
            </ColorButton>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TutorWelcomePage;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: '#ffffff',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
