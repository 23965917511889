import React, { useCallback, useEffect, useState } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import ClassesTile from '../../components/ClassesTile';
import TutorTile from '../../components/TutorTile';

const tabs = [
  { name: 'find_lessons', title: 'Find lessons', href: '/user/findclasses' },
  { name: 'find_tutor', title: 'Find tutors', href: '/user/findtutors' },
];

const ChildrenDashboard = () => {
  const [records, setRecords] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  useEffect(() => fetchMyTutors(), []);

  const fetchStudentRecord = useCallback(() => {
    if (!user) return;
    setRecords('loading');
    prepschoolClasses().get('/students', { params: { user_id: user.user_id } })
      .then((data) => {
        setRecords(data.data.data);
      })
      .catch((err) => {
        setRecords([]);
        console.log(err);
      });
  }, [user]);
  
  useEffect(() => fetchStudentRecord(), [fetchStudentRecord]);

  const fetchMyTutors = () => {
    setTutors('loading');
    prepschoolAuth().get('/tutors')
      .then((data) => {
        setTutors(data.data.data);
      })
      .catch((err) => {
        setTutors([]);
      });
  };

  return (
    <DashboardLayout tabs={tabs} currentTab="parents_dashboard" studentsOnly>
      <div className="tutors-dashboard">
        {records[0]
          ?
            <section className="classes-page-sec tutors-page-sec">
              <h4>My Subcribed Courses</h4>
              <div className="classes-found-cont" style={{justifyContent:'flex-start'}}>
                {records === 'loading'
                  ? <p>Courses loading...</p>
                  : records.map((item, idx) => {
                      return (<ClassesTile key={idx} record={item} />);
                    })
                }
              </div>
            </section>
          : null
        }
        {tutors[0]
          ?
            <section className="tutors-page-sec section-sizing classes-page-sec">
              <h4>Available Tutors</h4>
              <div className="classes-found-cont">
              {tutors === 'loading'
                ? <p>Tutors loading...</p>
                : tutors.map((item, idx) => {
                    return (<TutorTile tutor={item} key={idx} />);
                  })
              }
              </div>
            </section>
          : null
        }
      </div>
    </DashboardLayout>
  );
};

export default ChildrenDashboard;
