import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import { subjectCategories } from '../helpers/subjectCategories';

const SearchComponent = () => {
  const history = useHistory();

  const [subjects, setSubjects] = useState(subjectCategories);
  const [selected, setSelected] = useState(null);

  useEffect(() => fetchSubjects(), []);

  const fetchSubjects = () => {
    prepschoolClasses().get('/subjects')
      .then((data) => {
        setSubjects(data.data.data);
      })
      .catch((err) => {

      });
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.title,
  });

  const redirectToTutorsPage = useCallback(() => {
    if (!selected) return;
    history.push(`/user/findtutors?subjects=${selected.id}&skip=0`);
  }, [selected, history]);
  
  useEffect(() => { redirectToTutorsPage(); }, [selected, redirectToTutorsPage]);

  if (!subjects[0]) return null;

  return (
    <div id="category-search-bar" /*className="search-component"*/>
      <Autocomplete
        id="category-search-bar"
        options={subjects}
        getOptionLabel={(option) => option.title}
        filterOptions={filterOptions}
        style={{width: 300}}
        renderInput={(params) => (
          <TextField
            {...params}
            label="search categories"
            margin="normal"
            variant="outlined"
          />
        )}
        onChange={(e, value) => setSelected(value)}
      />
    </div>
  );
};

export default SearchComponent;
