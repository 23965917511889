import React, { useCallback, useContext, useEffect, useState } from 'react';
import SchoolIcon from '@material-ui/icons/School';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { store } from '../../global-store/popupContext';
import StudentsCard from '../../components/StudentsCard';
import DashboardLayout from '../../components/DashboardLayout';
import TutorNewBookingsCard from '../../components/TutorNewBookingsCard';
import TutorClassCard from '../../components/TutorClassCard';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { useHistory } from 'react-router-dom';
import { openTwoWayDialog } from '../../helpers/popupHelpers';

const TutorsDashboard = () => {
  const { dispatch } = useContext(store);
  const history = useHistory();

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [tutor] = useState(JSON.parse(localStorage.getItem('prepschool_tutor')));
  const [tutorCourses, setTutorCourses] = useState([]);
  const [tutorLiveCourses, setTutorLiveCourses] = useState([]);
  const [bookings, setBookings] = useState([]);
  
  const tabs = [
    { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
    { name: 'profile', title: 'My Profile', href: '/tutor-profile/' + user.user_id },
    { name: 'bookings', title: 'View Bookings', href: '/tutor/bookings/accept' }
  ];

  const getTutor = useCallback(() => {
    if (tutor) return;
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});

    prepschoolAuth().get('/tutors/' + user.user_id)
      .then((data) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        localStorage.setItem('prepschool_tutor', JSON.stringify(data.data.data));
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        if (err.response.status === 400) history.push('/become-a-tutor');
      });
  }, [tutor, user, history, dispatch]);
  
  useEffect(() => { getTutor(); }, [getTutor]);

  const fetchTutorCourses = useCallback(() => {
    prepschoolClasses().get('/courses', { params: { tutor: user.user_id } })
      .then((data) => {
        setTutorCourses(data.data.data);
      })
      .catch((err) => {
        console.log(err)
      });
  }, [user]);
  
  useEffect(() => { fetchTutorCourses(); }, [fetchTutorCourses]);

  const fetchTutorLiveCourses = useCallback(() => {
    prepschoolClasses().get('/live-courses', { params: { tutor: user.user_id } })
      .then((data) => {
        setTutorLiveCourses(data.data.data);
      })
      .catch((err) => {
        console.log(err)
      });
  }, [user]);
  
  useEffect(() => fetchTutorLiveCourses(), [fetchTutorLiveCourses]);

  const fetchBooking = useCallback(() => {
    const params = { tutor: user.user_id };

    prepschoolClasses().get('/bookings/live-courses', { params })
      .then((data) => {
        setBookings(data.data.data);
      })
      .catch(({ message, response }) => {
        console.log('error', message, response);
      })
  }, [user]);
  
  useEffect(() => fetchBooking(), [fetchBooking]);

  const openTutorTwoWayDialog = () => {
    return openTwoWayDialog(
      dispatch,
      'Choose Type of Course',
      <b>Choose the kind of course you'll like to upload. Click 'VIDEO COURSE' to upload prerecorded videos. Click 'LIVE COURSE' to deliver lectures in real time.</b>,
      'video course',
      'live course',
      () => history.push('/upload-classes'),
      () => history.push('/upload-classes/live')
    );
  };

  return (
    <DashboardLayout tabs={tabs} currentTab="parents_dashboard" tutorsOnly>
      <div className="tutors-dashboard">
        <div className="parents-db-page">
          <section className="parents-db-sec1">
            <div className="pds-top-cards">
              <TutorNewBookingsCard bookings={bookings} />
              <StudentsCard tutor={user.user_id} />
            </div>
          </section>
          <section>
            <div className="twp-flex-cont">
              <div className="twp-flex-item">
                <SchoolIcon fontSize="large" />
                <h3>Upload classes</h3>
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={openTutorTwoWayDialog}
                >
                  add class
                </ColorButton>
              </div>
              <div className="twp-flex-item">
                <SchoolIcon fontSize="large" />
                <div style={{position:'relative'}}>
                  <PhotoCameraOutlinedIcon style={{position:'absolute',top:-40,left:10,fontSize:40}} />
                </div>
                <h3>Start a live class</h3>
                <ColorButton
                  variant="contained"
                  color="primary"
                  href="/tutor/live-courses"
                >
                  go live
                </ColorButton>
              </div>
            </div>
          </section>
          {tutorCourses[0]
            ?
              <section className="parents-db-sec2">
                <h4>My Video Courses</h4>
                <div className="online-clses-cont">
                  {
                    tutorCourses.map((item, idx) => (
                      <TutorClassCard key={idx} course={item} />
                    ))
                  }
                </div>
              </section>
            : null
          }
          {tutorLiveCourses[0]
            ?
              <section className="parents-db-sec2">
                <h4>My Live Courses</h4>
                <div className="online-clses-cont">
                  {
                    tutorLiveCourses.map((item, idx) => (
                      <TutorClassCard key={idx} course={item} href={'/preview-live-class/'} />
                    ))
                  }
                </div>
              </section>
            : null
          }
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TutorsDashboard;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: '#ffffff',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
