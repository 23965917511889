import React, { useCallback, useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const initialValues = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
};

const daysOfTheWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
const timeSlots = ['8am - 10am', '10am - 12pm', '12pm - 2pm', '2pm - 4pm', '4pm - 6pm', '6pm - 8pm'];

const TutorAvailabilityTable = (props) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [tutor] = useState(JSON.parse(localStorage.getItem('prepschool_tutor')));

  const addSelected = (day, value) => {
    const newSchedules = [...formValues[day], value];
    setFormValues({ ...formValues, [day]: newSchedules });
  };

  const removeSelected = (day, value) => {
    const temp = { ...formValues };
    const scheduleIndex = temp[day].indexOf(value);
    temp[day].splice(scheduleIndex, 1);
    setFormValues(temp);
  };

  const validate = useCallback(() => {
    const vals = Object.values(formValues).flat();
    vals[0]
      ? props.setExternalInput('schedules', formValues, true)
      : props.setExternalInput('schedules', '', false)
  }, [formValues, props]);

  useEffect(() => { validate(); }, [formValues, validate]);

  return (
    <div className="tutor-availability-table" style={{width:'100%',overflowY:'auto'}}>
      <table style={{width:'100%',margin:'15px 0 30px'}}>
        <thead>
          <tr>
            <th></th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
            <th>Sun</th>
          </tr>
        </thead>
        <tbody>
          {timeSlots.map((slot, index) => {
            return (
              <tr key={index}>
                <td>{slot}</td>
                {daysOfTheWeek.map((day, idx) => {
                  return (
                    <td key={idx}>
                      <Checkbox
                        checked={
                          tutor || props.tutor
                            ? {...tutor, ...props.tutor}[day].split(',').includes(slot)
                            : formValues[day].includes(slot)
                        }
                        name={day}
                        onChange={(e) => e.target.checked ? addSelected(day, slot) : removeSelected(day, slot)}
                      />
                    </td>
                  );
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TutorAvailabilityTable;
