import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, Chip, Link, TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DashboardLayout from '../../components/DashboardLayout';
import { store } from '../../global-store/popupContext';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddQualificationComp from '../../components/AddQualificationComp';
import AddWorkExperienceComp from '../../components/AddWorkExperienceComp';
import TutorAvailabilityTable from '../../components/TutorAvailabilityTable';
import { prepschoolAuth } from '../../apis/prepschoolAuth';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { closePageLoader, displayPageLoader } from '../../helpers/popupHelpers';
import { ListOfBanksNigeria } from '../../constants';
import useValidate from '../../custom-hooks/useValidate';

const initialValues = {
  aboutYou: { value: '', validation: true },
  subjects: { value: '', validation: true },
  qualifications: { value: '', validation: true },
  experience: { value: '', validation: true  },
  schedules: { value: '', validation: true  },
  agreement: { value: '', validation: true },
  bank: { value: '', validation: true },
  account_no: { value: '', validation: true },
  file: { value: '', valdiation: true },
}

const BecomeATutor = (props) => {
  const { dispatch } = useContext(store);
  const classes = useStyles();
  const history = useHistory();

  const [formValues, setFormValues] = useState(initialValues);
  const [prepschoolSubjects, setPrepschoolSubjects] = useState([]);
  const [submitReady, setSubmitReady] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const valid = useValidate(formValues);

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = () => {
    prepschoolClasses().get('/subjects')
      .then((data) => {
        setPrepschoolSubjects(data.data.data);
        localStorage.setItem('prepschool_subjects', JSON.stringify(data.data.data));
      })
      .catch(err => console.log(err));
  };

  const getTutor = useCallback(() => {
    displayPageLoader(dispatch);

    prepschoolAuth().get('/tutors/' + user.user_id)
      .then((data) => {
        closePageLoader(dispatch);
        localStorage.setItem('prepschool_tutor', JSON.stringify(data.data.data));
        history.push('/users/tutors/dashboard');
      })
      .catch((err) => {
        closePageLoader(dispatch);
      });
  }, [user.user_id, dispatch, history]);
  
  useEffect(() => {
    if (!user) return;
    getTutor();
  }, [user, getTutor]);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
  };

  const setExternalInput = (name, value, validation) => {
    const formTemp = {...formValues};
    formTemp[name] = { value: value, validation };
    setFormValues(formTemp);
  };

  function handleFileInput(name, imageFile, allowedFormats, maxSize) {
    if (!imageFile)
      return setFormValues({ ...formValues, file: { value: '', validation: true } });

    const formTemp = {...formValues};
    let valid = false;

    if (!imageFile) valid = false;
    if (imageFile && imageFile.size <= maxSize) valid = true;
    if (imageFile && imageFile.size > maxSize) valid = `file size should not be more than ${maxSize / 1000000}mb`;
    if (imageFile && !allowedFormats.test(imageFile.name)) valid = 'wrong file format, choose an mp4 video file';

    formTemp[name] = { value: imageFile, validation: valid };
    setFormValues(formTemp);
  }


  const submitForm = () => {
    setSubmitReady(true);
    displayPageLoader(dispatch);
    const subsFormatted = prepschoolSubjects
      .filter((sub) => formValues.subjects.value.includes(sub.title))
      .map(sub => sub.id)
      .join(',') + ',';

    const form = new FormData();
    form.append('about', formValues.aboutYou.value);
    form.append('subjects', subsFormatted);
    form.append('sun', formValues.schedules.value.sun.join(','));
    form.append('mon', formValues.schedules.value.mon.join(','));
    form.append('tue', formValues.schedules.value.tue.join(','));
    form.append('wed', formValues.schedules.value.wed.join(','));
    form.append('thu', formValues.schedules.value.thu.join(','));
    form.append('fri', formValues.schedules.value.fri.join(','));
    form.append('sat', formValues.schedules.value.sat.join(','));
    form.append('price', '10000.00');
    form.append('account_no', formValues.account_no.value);
    form.append('bank', formValues.bank.value.name);
    form.append('video', formValues.file.value);
    
    prepschoolAuth().post('/tutors', form)
      .then((data) => {
        closePageLoader(dispatch);
        const tutor_id = user.user_id;
        dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 6000, message: 'Tutor successfully added' } });
        history.push('/tutor-profile/' + tutor_id);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Request could not be completed' } })
      });
  };

  return (
    <DashboardLayout /*proceedUnAuthenticated*/ tutorsOnly>
      <section className="parent-enroll-child">
        <div className="pec-container-div">
          <div className="pec-cont-div1">Become a Tutor</div>
          <div className="pec-cont-div2">
            <TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="about-you-field"
              fullWidth
              multiline
              rows={5}
              label="Tell us about yourself"
              helperText="This description will be shown to your students"
              variant="standard"
              value={formValues.aboutYou.value}
              error={!formValues.aboutYou.validation}
              onChange={(e) => {
                handleFormInput('aboutYou', e.target.value, /^/gi);
              }}
            />
            <Autocomplete
              style={{marginBottom:20}}
              multiple
              id="tags-filled"
              options={prepschoolSubjects.map((option) => option.title)}
              fullWidth
              //freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip fullWidth variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="standard"
                  label="Enter Subject or Skills you can teach"
                  placeholder="you can select multiple"
                />
              )}
              onChange={(e, value) => {
                handleFormInput('subjects', value, /./gi);
              }}
            />
            <Autocomplete
              className="form-fields"
              options={ListOfBanksNigeria}
              getOptionLabel={(option) => option.name}
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select your bank"
                  variant="standard"
                  fullWidth
                  error={!formValues.bank.validation}
                  style={{ marginBottom:20,width:'100%' }}
                />
              )}
              value={formValues.bank.value}
              onChange={(e, value) => handleFormInput('bank', value, /./gi)}
            />
            <TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="account-number"
              fullWidth
              label="Enter bank Account Number"
              helperText="This account will be used for your payouts"
              variant="standard"
              value={formValues.account_no.value}
              error={!formValues.account_no.validation}
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/gi, '').slice(0, 10);
                handleFormInput('account_no', value, /^\d{10}$/g);
              }}
            />
            <AddQualificationComp {...{ setExternalInput, submitReady }} />
            <AddWorkExperienceComp {...{ setExternalInput, submitReady }} />
            <TutorAvailabilityTable {...{ setExternalInput, submitReady }} />
            <div style={{width:'100%',marginBottom:50}}>
              <div className={formValues.file.validation !== true ? 'custom-file-field-error' : ''}
                style={{display:'flex',alignItems:'center',padding:5,borderRadius:4,border:'1px dashed #00000026'}}
              >
                <div className={classes.root}>
                  <input
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleFileInput('file', e.target.files[0], /./gi, 11000000)}
                  />
                  <label htmlFor="contained-button-file">
                    <Button className={classes.btnUpload} variant="contained" color="primary" component="span">
                      Upload
                    </Button>
                  </label>
                </div>
                <span>{formValues.file.value !== '' ? formValues.file.value.name : 'Upload a 2min. video of yourself (10mb max)'}</span>
              </div>
              <span style={{font:'normal 12px/15px Quicksand',letterSpacing:0.11,color:'#07134099',opacity:0.7}}>
                This Video is to help you market your skills as a tutor. mp4 only (10mb max)
              </span>
              <span style={{color:'#e53935'}}>{formValues.file.validation !== true ? formValues.file.validation : ''}</span>
            </div>
            <div className="terms-of-service" style={{marginBottom:50}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.agreement.value}
                    onChange={(e) => handleFormInput('agreement', e.target.checked, /true/)}
                    name="checkedA"
                  />
                }
                label={<label>I accept the <Link>terms and conditions</Link></label>}
              />
            </div>
            <div>
              <ColorButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={submitForm}
                disabled={valid}
              >
                sign up
              </ColorButton>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default BecomeATutor;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  input: {
    display: 'none',
  },
  btnContained: {
    height: 56,
    backgroundColor: 'black',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    marginBottom: 20,
    maxWidth: 190,
  },
  btnOutlined: {
    height: 56,
    backgroundColor: 'transparent',
    border: '1px solid black',
    color: 'black',
    fontSize: 14,
    letterSpacing: 1.4,
    marginBottom: 20,
    maxWidth: 190,
  },
  btnUpload: {
    height: 44,
    backgroundColor: 'black',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 190,
  }
}));
