import React from 'react';
import { Button, Link } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import { withStyles } from '@material-ui/core/styles';

const Footer = () => {
  return (
    <footer >
      <div className="footer-div1">
        <div className="footer-div1-item1">
          <h4> WHAT'S NEXT</h4>
          <p>Start learning with prepschool</p>
        </div>
        <div className="footer-div1-item2">
          <ColorButton
            variant="contained"
            color="primary"
            href="/user/findtutors"
          >
            Find a tutor
          </ColorButton>
          <p>
          <span>Are you a tutor?</span> <Link href="/signup?role=tutor">Click here</Link>
          </p>
        </div>
      </div>
      <hr />
      <div className="footer-div2 two-col-flex">
        <div className="footer-div2-item1">
          <h5>PREPSCHOOL</h5>
          <p>We provide a convenient flexible indigenous platform for students to learn new skill that will help develop them.</p>
        </div>
        <div className="footer-div2-item2">
          <h5>SITE MAP</h5>
          <ul>
            <li>TutorMe</li>
            <li>Become a Tutor</li>
          </ul>
        </div>
        <div className="footer-div2-item3">
          <h5>LEGAL</h5>
          <ul>
            <li>Terms of Service</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div className="footer-div2-item4">
          <h5>CONTACT US</h5>
          <ul>
            <li><MailIcon fontSize="small" /> hello@prepschool.com</li>
            <li><PhoneIcon fontSize="small" /> +2348110521206</li>
          </ul>
          {/*<Button>Chat with us</Button>*/}
        </div>
      </div>
      <p className="index-copyright">© Prepschool 2020</p>
    </footer>
  );
};

export default Footer;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
