import { useEffect, useState } from "react";

export default function useValidate(formValues) {
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const temp = Object.values(formValues).every((value) => (
      value.value !== '' && value.validation === true
    ));
    setValid(temp);
  }, [formValues]);

  return !valid;
};
