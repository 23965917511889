import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { store } from '../../global-store/popupContext';

function ConfirmAction() {
  const { state, dispatch } = useContext(store);

  const handleClose = () => {
    dispatch({ type: 'DISPLAY_CONFIRM_ACTION_MODAL', payload: { open: false } });
  };

  const handleAction = () => {
    state.confirmActionModal.action();
    handleClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={state.confirmActionModal.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <strong style={{fontSize:18}}>
          {state.warning ? state.warning : 'Continue with this action?'}
        </strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            state.confirmActionModal.message
              ? state.confirmActionModal.message
              : 'Are you sure you want to continue with this action?'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" style={{color:'#e24848'}}>
          Cancel
        </Button>
        <Button onClick={handleAction} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmAction;
