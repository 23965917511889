import React, { useState, useEffect, useCallback } from 'react';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import ClassesTile from '../../components/ClassesTile';
import DashboardLayout from '../../components/DashboardLayout';

const LearningPlanPage = () => {

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [records, setRecords] = useState([]);

  const fetchStudentRecord = useCallback(() => {
    if (!user) return;
    prepschoolClasses().get('/students', { params: { user_id: user.user_id } })
      .then((data) => {
        setRecords(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);
  
  useEffect(() => { fetchStudentRecord(); }, [fetchStudentRecord]);

  return (
    <DashboardLayout>
      <section className="learning-plan-page">
        <div className="lpp-top-div">
          <h3>My Learning Plan</h3>
          <p>Keep track of all the Lessons you've added.<br/>Let's get started!</p>
        </div>
        <div className="lpp-bottom-div">
          {
            records.map((item, idx) => (
              <ClassesTile key={idx} record={item} />
            ))
          }
        </div>
      </section>
    </DashboardLayout>
  );
};

export default LearningPlanPage;
