const axios = require('axios');

let token = localStorage.getItem("authorization");

if (!token) token = '';

const baseURL = process.env.REACT_APP_CLASSES_SERVICE;//"http://localhost:8000/api/v1";
//const baseURL = "https://prepschool-classes.herokuapp.com/api/v1";

const prepschoolClassesApi = axios.create({
  baseURL,
  headers: { Authorization: "Bearer " + token }
});

export const prepschoolClasses = () => {
  const authToken = localStorage.getItem('authorization');

  return axios.create({
    baseURL,
    headers: { Authorization: "Bearer " + authToken }
  });
};

export const prepschoolClassesUrl = baseURL;

export default prepschoolClassesApi;
