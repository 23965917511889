import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Link, makeStyles, TextField, withStyles } from '@material-ui/core';
import DashboardLayout from '../../components/DashboardLayout';
import { handleTimeInput, handleFormInput, validate } from '../../helpers/validation';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { store } from '../../global-store/popupContext';
import { closePageLoader, displayPageLoader, displayAlert } from '../../helpers/popupHelpers';
import { prepschoolLiveClassArena } from '../../apis/urls';

const initialValues = {
  title: { value: '', validation: true },
  date: { value: '', validation: true },
  schedule: { value: '', validation: true },
}

const PreviewLiveClass = (props) => {
  const classes = useStyles();

  const course_id = props.match.params.id;

  const { dispatch } = useContext(store);

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [formValues, setFormValues] = useState(initialValues);
  const [liveClasses, setLiveClasses] = useState([]);
  const [course, setCourse] = useState(null);
  const [reloadClasses, setReloadClasses] = useState(true);
  const [fadeBtn, setFadeBtn] = useState(true);

  useEffect(() => fetchCourseClasses(), [reloadClasses])
  useEffect(() => validate(formValues, setFadeBtn), [formValues]);
  
  const tabs = [
    { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
    { name: 'profile', title: 'My Profile', href: '/tutor-profile/' + user.user_id },
    { name: 'bookings', title: 'View Bookings', href: '/tutor/bookings/accept' }
  ];

  const submitClass = () => {
    displayPageLoader(dispatch);
    const splitSchedule = formValues.schedule.value.split('-');
    const payload = {
      course: course_id,
      title: formValues.title.value,
      date: formValues.date.value,
      time_from: splitSchedule[0],
      time_to: splitSchedule[1],
    };

    prepschoolClasses().post('/live-classes', payload)
      .then(() => {
        closePageLoader(dispatch);
        displayAlert(dispatch, 'success', 'lesson created successfully!!');
        setReloadClasses(!reloadClasses);
        setFormValues(initialValues);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  };

  const fetchCourse = useCallback(() => {
    prepschoolClasses().get('/live-courses/' + course_id)
      .then((data) => {
        setCourse(data.data.data);
      })
      .catch();
  }, [course_id]);

  useEffect(() => fetchCourse(), [fetchCourse]);

  const fetchCourseClasses = () => {
    prepschoolClasses().get('/live-classes')
      .then((data) => {
        setLiveClasses(data.data.data);
      })
      .catch()
  }

  const renderJoinBtn = (date, from, to, lesson) => {
    if (!date || !from || !to || !lesson) return null;

    const { role, token } = user;
    const lessonDate = new Date(date);
    const timeFromArr = from.split(':');
    const timeToArr = to.split(':');
    const timeFrom = lessonDate.setHours(timeFromArr[0], timeFromArr[1], 0);
    const timeTo = lessonDate.setHours(timeToArr[0], timeToArr[1], 0);
    const today = (new Date()).getTime();

    return (
      <Button
        variant="contained"
        disabled={timeFrom < today && today < timeTo ? false : true}
        onClick={() => window.open(
          `${prepschoolLiveClassArena}?course=${course_id}&lesson=${lesson}&token=${token}`
          )
        }
      >
        {role === 'tutor' ? 'start' : 'join'}
      </Button>
    );
  };

  if (!course) return null;

  return (
    <DashboardLayout tabs={tabs} tutorsOnly>
      <div className="preview-live-class-page course-arena">
        <div className="co-arena-top-div">
          <div className="top-div-div1">
            <h1>{course.title}</h1>
            {/* <p>{course.description}</p> */}
          </div>
          <div className="top-div-div3">
            <p><Link href={'/update-classes/live/' + props.match.params.id}>Edit Course</Link></p>
          </div>
        </div>
        <div className="co-arena-bottom-div">
          <div className="co-ar-bt-div-item">
            <h3>Lesson overview</h3>
            {<ul>
              {liveClasses.map((item, idx) => (
                <li key={idx}>
                  <input type="checkbox" checked={false} />
                  <p style={{flex:1}}>{idx + 1}. {item.title}</p>
                  <span style={{fontSize:13,marginRight:5,opacity:0.6}}>
                    {(new Date(item.date)).getFullYear()}-{(new Date(item.date)).getMonth()}-{(new Date(item.date)).getDay()}
                    <b> {item.time_from}</b>
                  </span>
                  {renderJoinBtn(item.date, item.time_from, item.time_to, item.title)}
                </li>
              ))}
            </ul>}
          </div>
          {user.role === 'tutor'
          ? <div className="add-live-lesson">
              <h3 className="alles-h3">Create Live Lesson</h3>
              <div style={{display:'flex',flexWrap:'wrap'}}>
                <TextField
                  className="form-fields"
                  style={{marginBottom:20,flex:1,minWidth:300}}
                  autoFocus
                  id="serial_no-field"
                  label="Class Title"
                  placeholder="Eg. Intro to basic mathematics"
                  variant="standard"
                  value={formValues.title.value}
                  error={!formValues.title.validation}
                  onChange={(e) => handleFormInput('title', e.target.value, /^/gi, formValues, setFormValues)}
                />
                <TextField
                  id="time"
                  label="date"
                  type="date"
                  value={formValues.date.value}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  //inputProps={{ step: 300 }} // 5 min
                  style={{marginLeft:10,marginBottom:20}}
                  onChange={({ target: { value } }) => {
                    handleFormInput('date', value, /^./g, formValues, setFormValues);
                  }}
                />
                <div style={{display:'flex',marginBottom:20,minWidth:300,fontWeight:'bold',marginLeft:10}}>
                  <span style={{fontWeight:400,fontSize:12}}>Time range:</span>
                  <TextField
                    id="time"
                    label="From"
                    type="time"
                    //defaultValue="07:30"
                    value={formValues.schedule.value.split('-')[0]}
                    className={classes.textField}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{step: 300}} // 5 min
                    style={{margin:'0 20px',flex:3}}
                    onChange={({ target: { value } }) => {
                      handleTimeInput('schedule', 'from', value, formValues, setFormValues)
                    }}
                  />
                  <TextField
                    id="time"
                    label="To"
                    type="time"
                    //defaultValue="07:30"
                    value={formValues.schedule.value.split('-')[1]}
                    className={classes.textField}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }} // 5 min
                    style={{flex:3}}
                    onChange={({ target: { value } }) => {
                      handleTimeInput('schedule', 'to', value, formValues, setFormValues)
                    }}
                  />
                </div>
              </div>
              <div className="ucpage-div2-btn-div">
                <DarkBlueButton
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={fadeBtn}
                  onClick={submitClass}
                >
                  Schedule Lesson
                </DarkBlueButton>
              </div>
            </div>
          : null}
        </div>
      </div>
    </DashboardLayout>
  )
}

export default PreviewLiveClass;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const DarkBlueButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'black',
    },
    backgroundColor: '#071340',
    height: 45,
    color: 'white',
    //width: 160,
    fontSize: 14,
    letterSpacing: 1.4,
  },
}))(Button);
