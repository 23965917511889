import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button, Link } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import DashboardLayout from '../components/DashboardLayout';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import TutorAvailabilityTable from '../components/TutorAvailabilityTable';
import { prepschoolAuth } from '../apis/prepschoolAuth';
import { store } from '../global-store/popupContext';
import { prepschoolClasses } from '../apis/prepschoolClasses';

const tabs = [
  { name: 'home', title: 'Home', href: '/users/tutors/dashboard' },
  { name: 'courses', title: 'My Courses', href: '/user/findclasses' }
];

const UserViewTutor = (props) => {
  const classes = useStyles();
  const { tutor_id, tutor_name } = props.match.params;

  const { dispatch } = useContext(store);

  const [submitReady] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [tutor, setTutor] = useState(null);
  const [tutorCache] = useState(JSON.parse(localStorage.getItem('prepschool_tutor')));
  const [subjectsCache] = useState(JSON.parse(localStorage.getItem('prepschool_subjects')) || []);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  const setExternalInput = () => {};

  const getTutor = useCallback(() => {
    //if (tutorCache) return;
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});

    prepschoolAuth().get('/tutors/profile/' + tutor_id, { params: { tutor_name } })
      .then((data) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        setTutor(data.data.data);
        //localStorage.setItem('prepschool_tutor', JSON.stringify(data.data.data));
      })
      .catch(() => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
      });
  }, [tutor_id, tutor_name, dispatch]);

  const fetchSubjects = useCallback(() => {
    //if (subjectsCache[0]) return;
    prepschoolClasses().get('/subjects')
      .then((data) => {
        setSubjects(data.data.data);
        //localStorage.setItem('prepschool_subjects', JSON.stringify(data.data.data));
      })
      .catch(err => console.log(err));
  }, []);
  
  useEffect(() => {
    getTutor();
    fetchSubjects();
  }, [getTutor, fetchSubjects]);

  const renderVideo = () => {
    if (!tutorCache && !tutor) return null;
    const url = ({...tutorCache, ...tutor}).video
      
    return (
      <video controlsList="nodownload" controls autoPlay>
        <source src={url} type="video/mp4" />
        <source src={url} type="video/ogg" />
        Your browser does not support HTML video.
      </video>
    );
  }

  return (
    <DashboardLayout tabs={tabs} proceedUnAuthenticated>
      <section className="tutor-profile-page class-detail-page">
        {/*<div className="cdp-bk-btn-div">
          <Link href={`/view-tutor/${props.match.params.id}`}>back</Link>
        </div>*/}
        {!user
          ?
            <div className="cdp-price-div">
              <p>&#8358;{tutor ? Number(tutor?.price).toLocaleString() : '5,000'}/hour</p>
              <Button
                variant="contained"
                color="primary"
                classes={{root: classes.btnRoot}}
                href={`/hire-tutor/${tutor_id}`}
              >
                hire this tutor
              </Button>
            </div>
          : null
        }
        <div className="cdp-main-div">
          <div className="cdp-main-top-div">
            <Avatar alt="prepschool tutor" src={avatarPlaceholder} className={classes.largeAvatar} />
            <div className="cdp-rating-div">
              <h1 style={{textTransform:'capitalize'}}>
                {tutor?.first_name} {tutor?.last_name}
              </h1>
              <Rating value={3.5} precision={0.5} />
            </div>
            <h2 style={{textTransform:'capitalize'}}>About <Link to="#">{tutor?.first_name} {tutor?.last_name}</Link></h2>
          </div>
          <div className="cdp-main-top-div2">
            {renderVideo()}
            <p>{tutor ? tutor?.about : ''}</p>
          </div>
          <div className="cdp-main-top-div3">
            <div className="cdp-mtd-item1 cdp-mtd-item">
              <h3>Skills <span style={{textTransform:'capitalize'}}><Link to="#">{tutor?.first_name}</Link></span> can offer</h3>
              <ul>
                {(subjects[0] || subjectsCache[0]) && (tutor || tutorCache)
                  ? ([...subjectsCache, ...subjects]).filter((sub) => {
                      return ({...tutor, ...tutorCache}).subjects.split(',').includes(String(sub.id))
                    })
                    .map((sub, idx) => {
                      return <li key={idx}><div></div>{sub.title}</li>
                    })
                  : null}
              </ul>
            </div>
            <div className="cdp-mtd-item2 cdp-mtd-item">
              <h3>Work Experience</h3>
              <div className="">
                {tutor || tutorCache
                  ? ({...tutorCache, ...tutor}).experience.map((exp, idx) => (
                      <div key={idx} className="cdp-mtd-item2-item">
                        <div>
                          <h4>{exp.title}</h4>
                          <span>{exp.company}</span>
                        </div>
                        <span>{exp.year}</span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="cdp-mtd-item3 cdp-mtd-item2 cdp-mtd-item">
              <h3>Qualification</h3>
              <div>
                {tutor || tutorCache
                  ? ({...tutorCache, ...tutor}).qualifications.map((qual, idx) => (
                      <div key={idx} className="cdp-mtd-item2-item">
                        <div>
                          <h4>{qual.title}</h4>
                          <span>{qual.institution}</span>
                        </div>
                        <span>{qual.year}</span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="cdp-mtd-item4 cdp-mtd-item">
              <h3>Availability</h3>
              <div className="cdp-mtd-table-div">
                <TutorAvailabilityTable
                  {...{ setExternalInput, submitReady }}
                  tutor={(tutor || tutorCache) ? { ...tutorCache, ...tutor } : null}
                />
              </div>
            </div>
          </div>
          {!user
            ?
              <div className="cdp-main-top-div4">
                <Button
                  variant="contained"
                  color="primary"
                  classes={{root: classes.btnRoot}}
                  href={`/hire-tutor/${tutor_id}`}
                >
                  hire
                </Button>
              </div>
            : null
          }
        </div>
      </section>
    </DashboardLayout>
  );
};

export default UserViewTutor;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
  largeAvatar: {
    width: 90,
    height: 90,
  },
}));
