import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import DashboardLayout from '../components/DashboardLayout';
import TutorsFilter from '../components/TutorsFilter';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import TutorsLiveClassCard from '../components/TutorsLiveClassCard';
import { prepschoolLiveClassArena } from '../apis/urls';
import { useHistory } from 'react-router';

const tabs = [
  { name: 'find_tutor', title: 'Find Tutor', href: '/user/findtutors' },
  { name: 'find_lessons', title: 'Video courses', href: '/user/findclasses' },
];

const FindLiveCourses = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const pageLimit = 30;
  const queryParam = new URLSearchParams(props.location.search.slice(1));

  const initialValues = {
    skill: { value: queryParam.get('subjects') !== 'null' ? queryParam.get('subjects') : '' },
    price: { value: queryParam.get('price') !== 'null' ? queryParam.get('price') : '' },
    skip: { value: queryParam.get('skip') !== 'null' ? queryParam.get('skip') : '' },
  };

  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [tutorLiveCourses, setTutorLiveCourses] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  
  const fetchTutorLiveCourses = useCallback(() => {
    setCoursesLoading(true);
    const { skill, price, skip } = formValues;

    let queryString = `/user/live-courses?subjects=${skill.value}&price=${price.value}`;
    queryString += `&skip=${skip.value}&limit=${pageLimit}`;

    history.push(queryString);

    const params = {
      status: 'published', subject_tags: skill.value, price: price.value,
      skip: skip.value, limit: pageLimit,
    };
    prepschoolClasses().get('/live-courses', { params })
      .then((data) => {
        setCoursesLoading(false);
        setTutorLiveCourses(data.data.data);
      })
      .catch((err) => {
        setCoursesLoading(false);
        console.log(err)
      });
  }, [formValues, history]);
  
  useEffect(() => { fetchTutorLiveCourses(); }, [formValues, fetchTutorLiveCourses]);
  
  const fetchNextPage = (page) => {
    const skip = (page - 1) * pageLimit;
    setFormValues({ ...formValues, skip: { value: skip } });
  }

  return (
    <DashboardLayout tabs={tabs} proceedUnAuthenticated>
      <TutorsFilter {...{ formValues, setFormValues }} />
      <section className="classes-page-sec tutors-page-sec">
        <h4>Live Courses</h4>
        <div className="ttps-search-res">
          <span><b>{tutorLiveCourses.length} Live Course(s)</b> matching your search</span>
          {/*<Autocomplete
            className="form-fields"
            options={[]}
            defaultValue="popularity"
            renderInput={params => (
              <TextField
                {...params}
                label="sort by"
                variant="standard"
                //error={!formValues.role.validation}
              />
            )}
            //value={formValues.role.value}
            //onChange={(e, value) => handleFormInput('role', value, /./gi)}
          />*/}
        </div>
        {coursesLoading
          ? <div>Loading courses...</div>
          : null
        }
        <div className="classes-found-cont">
          {tutorLiveCourses.map((item, idx) => {
            return (
              <TutorsLiveClassCard
                key={idx} course={item} 
                href={`${prepschoolLiveClassArena}?token=${user.token}&topic=${item.title}`}
              />
            )
          })}
        </div>
      </section>
      <div className={classes.root}>
        <Pagination
          count={30}
          variant="outlined"
          shape="rounded"
          page={Number(formValues.skip.value/pageLimit) + 1 || 1}
          onChange={(event, page) => fetchNextPage(page)}
        />
      </div>
    </DashboardLayout>
  );
};

export default FindLiveCourses;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width:'80%',
      margin:'auto',
      marginTop: theme.spacing(2),
      marginBottom: 50,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
