import React, { useCallback, useEffect, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChatIcon from '@material-ui/icons/Chat';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { Button, Link, withStyles } from '@material-ui/core';
import { prepschoolClasses } from '../apis/prepschoolClasses';

const TutorBlock = ({ tutor }) => {
  const [subjects, setSubjects] = useState([]);
  const [subjectsCache] = useState(JSON.parse(localStorage.getItem('prepschool_subjects')) || []);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  const fetchSubjects = useCallback(() => {
    if (subjectsCache[0]) return;
    prepschoolClasses().get('/subjects')
      .then((data) => {
        setSubjects(data.data.data);
        localStorage.setItem('prepschool_subjects', JSON.stringify(data.data.data));
      })
      .catch(err => console.log(err));
  }, [subjectsCache]);
  
  useEffect(() => { fetchSubjects(); }, [fetchSubjects]);
  
  if (!tutor) return null;

  return (
    <div className="tutor-block tutor-tile-cont">
      <div className="ttc-avatar-div">
        <div><img alt={tutor?.firstName || ""} src={avatarPlaceholder} /></div>
      </div>
      <div className="ttc-content-div">
        <div className="ttc-content-top">
          <div>
            <p>Bismark Achodo</p>
            <Rating value={3.5} precision={0.5} readOnly />
          </div>
          <div className="tblock-proflink-div ttc-price">
            <div className="proflink">
              <Link href={`/tutor-profile/${tutor.user_id}`}>View Profile</Link>
            </div>
            {!['tutor', 'child'].includes(user.role)
              ?
                <ColorButton
                  variant="contained"
                  color="primary"
                  href={`/hire-tutor/${tutor.user_id}`}
                  disabled={user.role === 'student'}
                >
                  hire tutor
                </ColorButton>
              : null
            }
          </div>
        </div>
        <div className="tblock-flex-wrapper">
          <div className="ttc-content-mid">
            <ul>
              {(subjects[0] || subjectsCache[0]) && (tutor)
                ? ([...subjectsCache, ...subjects]).filter((sub) => {
                    return tutor.subjects.split(',').includes(String(sub.id))
                  })
                  .map((sub, idx) => {
                    return <li key={idx}><div></div>{sub.title}</li>
                  })
                : null}
            </ul>
          </div>
          <div className="ttc-content-down">
            <div className="ttc-icon-div">
              <div><BookmarkIcon fontSize="small" color="inherit" /></div>
              <div><ChatIcon fontSize="small" color="inherit" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorBlock;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200,
  },
}))(Button);
