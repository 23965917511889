import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button, FormControlLabel, Link } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Checkbox from '@material-ui/core/Checkbox';
import DashboardLayout from '../components/DashboardLayout';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import { store } from '../global-store/popupContext';
import { openConfirmDialog } from '../helpers/popupHelpers';
import { useHistory } from 'react-router-dom';
import { paymentRedirect } from '../helpers/messages';
import { initiateCoursePayment } from '../helpers/payments';

const ClassDetailPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  
  const { dispatch } = useContext(store);

  const [course, setCourse] = useState(null);
  const [metaData, setMetaData] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  const fetchCourseMetaData = useCallback(() => {
    prepschoolClasses().get(`/classes/course-title/${props.match.params.id}`)
      .then((data) => {
        setMetaData(data.data.data);
      })
      .catch();
  }, [props]);
  
  useEffect(() => { fetchCourseMetaData(); }, [fetchCourseMetaData]);

  const fetchCourse = useCallback(() => {
    if (course) return;
    prepschoolClasses().get(`/courses/${props.match.params.id}`)
      .then((data) => {
        setCourse(data.data.data);
      })
      .catch();
  }, [props, course]);
  
  useEffect(() => { fetchCourse(); }, [fetchCourse]);

  const checkIfUserIsStudent = useCallback(() => {
    if (user.role === 'parent') return;
    prepschoolClasses().get(`/students/${props.match.params.id}/${user.user_id}`)
      .then((data) => {
        if (data.data.data)
          return history.push(`/course-arena/${props.match.params.id}/0`);
      })
      .catch();
  }, [props, user, history]);
  
  useEffect(() => { checkIfUserIsStudent(); }, [checkIfUserIsStudent]);

  const renderEnrollButton = () => {
    if (user.role === 'parent') return (
      <Button
        variant="contained"
        color="primary"
        classes={{root: classes.btnRoot}}
        href={`/users/enroll-child/${props.match.params.id}`}
      >
        Enroll child
      </Button>
    );
    return (
      <Button
        variant="contained"
        color="primary"
        classes={{root: classes.btnRoot}}
        onClick={() => openConfirmDialog(
          dispatch,
          () => initiateCoursePayment(course, [user], user, dispatch),
          paymentRedirect
        )}
      >
        Enroll
      </Button>
    )
  };

  if (!course) return null;

  return (
    <DashboardLayout proceedUnAuthenticated>
      <section className="class-detail-page">
        <div className="cdp-bk-btn-div">
          <Link href="/user/findclasses">back</Link>
        </div>
        <div className="cdp-price-div">
          <p>&#8358;{course.price}</p>
          {renderEnrollButton()}
        </div>
        <div className="cdp-main-div">
          <div className="cdp-main-top-div">
            <h1>Drawing Classes for Kids</h1>
            <div className="cdp-rating-div">
              <Rating value={3.5} precision={0.5} />
              <Avatar alt="prepschool tutor" src={avatarPlaceholder} />
            </div>
          </div>
          <div className="cdp-main-top-div2">
            <img src={course.poster} alt={course.title} style={{width: '100%'}}/>
            {/*
            <video controlsList="nodownload" controls>
              <source src={null} type="video/mp4" />
              <source src={null} type="video/ogg" />
              Your browser does not support HTML video.
            </video>
            */}
            <p>{course.description}</p>
          </div>
          <div className="cdp-main-top-div3">
            <h3>Lessons</h3>
            <div className="lessons-flex-cont">
              {metaData.map((item, idx) => (
                <div key={idx} className="lessons-flex-item">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        //onChange={(e) => handleFormInput('isAdult', e.target.checked, /true/)}
                        name="checkedA"
                      />
                    }
                    label={<label>{item.title}</label>}
                  />
                  <span>4Min</span>
                </div>
              ))}
            </div>
          </div>
          <div className="cdp-main-top-div4">
            {renderEnrollButton()}
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default ClassDetailPage;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}));
