import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { subjectCategories } from '../helpers/subjectCategories';

const TutorsFilter = ({ formValues, setFormValues }) => {
  
  const [skills] = useState(subjectCategories);

  const handleFilterChange = (name, value) => {
    if (!value) return;
    setFormValues({ ...formValues, [name]: { value }, skip: { value: 0 } });
  };
  
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.title,
  });

  return (
    <div className="tutors-filter">
      <div className="ttf-form-container">
        <form>
          {/*<div className="ttf-field-wrapper">
            <Autocomplete
              className="form-fields"
              options={['88']}
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label="Location"
                  variant="standard"
                  fullWidth
                  //error={!formValues.role.validation}
                  style={{ marginBottom:20,width:'100%' }}
                />
              )}
              value={''}
              onChange={(e, value) => {}}
            />
          </div>*/}
          <div className="ttf-field-wrapper">
            <Autocomplete
              className="form-fields"
              options={subjectCategories}
              getOptionLabel={(option) => option.title}
              filterOptions={filterOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Skill"
                  variant="standard"
                  fullWidth
                  style={{ marginBottom:20,width:'100%' }}
                />
              )}
              value={skills[formValues.skill.value - 1]}
              onChange={(e, value) => handleFilterChange('skill', value?.id, /./gi)}
            />
          </div>
          {/*<div className="ttf-field-wrapper">
            <Autocomplete
              className="form-fields"
              options={[]}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Online"
                  variant="standard"
                  fullWidth
                  //error={!formValues.role.validation}
                  style={{ marginBottom:20,width:'100%' }}
                />
              )}
              //value={formValues.role.value}
              //onChange={(e, value) => handleFormInput('role', value, /./gi)}
            />
          </div>*/}
          <div className="ttf-field-wrapper">
            <Autocomplete
              className="form-fields"
              options={['0 - 10000', '10000 - 50000', '50000 - 100000']}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Price (Naira)"
                  variant="standard"
                  fullWidth
                  style={{ marginBottom:20, width:'100%' }}
                />
              )}
              value={formValues.price.value}
              onChange={(e, value) => handleFilterChange('price', value, /./gi)}
            />
          </div>
        </form>
        {/*<span
          className="ttf-submit-btn"
          onClick={(e) => {}}
        >Find a tutor</span>*/}
      </div>
    </div>
  );
};

export default TutorsFilter;
