import React, { useState, useContext } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { store } from '../../global-store/popupContext';
import OnboardingLayout from '../../components/OnboardingLayout';
import prepschoolAuth from '../../apis/prepschoolAuth';
import { Link, useHistory } from 'react-router-dom';
import { findRoleDashboard } from '../../helpers/authHelpers';
import useValidate from '../../custom-hooks/useValidate';

const initialValues = {
  email: { value: '', validation: true },
  password: { value: '', validation: true },
}

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  
  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);

  const disabledBtn = useValidate(formValues);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
  }

  const submitForm = () => {
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});
    const signinForm = {
      email: formValues.email.value,
      password: formValues.password.value
    };
    prepschoolAuth.post('/signin', signinForm)
      .then((data) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        localStorage.setItem('prepschool_auth', JSON.stringify(data.data.user));
        localStorage.setItem('authorization', data.data.user.token);
        findRoleDashboard(data.data.user.role, history);
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Request could not be completed' } })
      });
  };

  return (
    <OnboardingLayout>
      <div className="onbdg-title">Login to your account</div>
      <form>
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          id="email-field"
          fullWidth
          label="Email"
          variant="standard"
          value={formValues.email.value}
          error={!formValues.email.validation}
          onChange={(e) => handleFormInput('email', e.target.value, /./gi)}
        />
        <TextField
          className="form-fields"
          id="password-field"
          style={{marginBottom:40}}
          type="password"
          label="Password"
          placeholder="eg 1d#8%sd232Yds$"
          variant="standard"
          fullWidth
          value={formValues.password.value}
          error={!formValues.password.validation}
          onChange={(e) => handleFormInput('password', e.target.value, /./gi)}
        />
      </form>
      <div className="submit-btn-div">
        <div>
          <p>
            <Link to="/forgot-password/initiate">Forgot password?</Link>
          </p>
          <Link to="/signup">Sign up here</Link>
        </div>
        <Button
          variant="contained"
          color="primary"
          classes={{root: classes.btnRoot}}
          fullWidth
          disabled={disabledBtn}
          onClick={submitForm}
        >
          Login
        </Button>
      </div>
    </OnboardingLayout>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    width: 150
  },
}));
