import React, { useCallback, useContext, useEffect, useState } from "react";
import MUIRichTextEditor from "mui-rte";
import { useHistory } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import PercentageCircle from '../components/percentage-circle/PercentageCircle';
import { closePageLoader, displayPageLoader, displayAlert } from '../helpers/popupHelpers';
import { prepschoolClasses } from '../apis/prepschoolClasses';
import { store } from '../global-store/popupContext';

const tabs = [
  { id: 'class_room', title: 'Class room' },
  { id: 'notes', title: 'Notes' },
  { id: 'chats', title: 'Chats' },
];

const CourseArena = (props) => {
  const history = useHistory();
  const { dispatch } = useContext(store);

  const [currentTab, setCurrentTab] = useState('class_room');
  const [classes, setClasses] = useState(JSON.parse(localStorage.getItem('course_meta_data')) || []);
  const [currentVideo] = useState(props.match.params.index);
  const [studentCourseRecord, setStudentCourseRecord] = useState(null);
  const [course] = useState(props.match.params.id);
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  const save = (data) => {
    console.log(JSON.parse(data));
  };
  
  const goToNextVideo = (idx) => {
    if (Number(idx) > classes.length - 1) return;
    history.push(`/course-arena/${course}/${idx}`);
    window.location.reload();
  };

  const fetchMetaData = useCallback(() => {
    if (localStorage.getItem('course_meta_data')) return;
    displayPageLoader(dispatch);

    prepschoolClasses().get('/classes/course/' + props.match.params.id)
      .then((data) => {
        closePageLoader(dispatch);
        const sorted = data.data.data.sort((a, b) => a.serial_no - b.serial_no);
        localStorage.setItem('course_meta_data', JSON.stringify(sorted));
        setClasses(sorted);
      })
      .catch((err) => {
        closePageLoader(dispatch);
        if (err.response) displayAlert(dispatch, 'error', err.response.data.error);
        else displayAlert(dispatch, 'error', 'Request could not be completed');
      });
  }, [props, dispatch]);
  
  useEffect(() => { fetchMetaData(); }, [fetchMetaData]);

  const fetchStudentCourseRecord = useCallback(() => {
    const details = JSON.parse(localStorage.getItem('student-course-' + course));
    if (details && details.course) return;

    prepschoolClasses().get(`/students/${props.match.params.id}/${user.user_id}`)
      .then((data) => {
        localStorage.setItem('student-course-' + course, JSON.stringify(data.data.data));
        setStudentCourseRecord(data.data.data);
      })
      .catch();
  }, [course, user, props]);
  
  useEffect(() => { fetchStudentCourseRecord(); }, [fetchStudentCourseRecord]);

  const renderVideo = () => {
    if (classes[currentVideo]) {
      const fullUrl = classes[currentVideo].video;
      
      return (
        <video controlsList="nodownload" controls autoPlay
          onEnded={() => goToNextVideo(Number(currentVideo) + 1)}
        >
          <source src={fullUrl} type="video/mp4" />
          <source src={fullUrl} type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      );
    }
    return null
  }

  const renderProgress = () => {
    const studCourseCached = JSON.parse(localStorage.getItem('student-course-' + course));
    let aggregate = { ...studentCourseRecord, ...studCourseCached };

    PercentageCircle.defaultProps = {
      ...PercentageCircle.defaultProps,
      percentage: aggregate.progress
    };

    return <PercentageCircle />;
  };

  return (
    <DashboardLayout childOrStudentOnly>
      <div className="course-arena">
        <div className="co-arena-top-div">
          <div className="top-div-div1">
            <h1>Drawing Classes for Kids</h1>
            <p>Learn how to draw anything and become a master in creating wonderful images.</p>
          </div>
          <div className="top-div-div3">
            <p>Lesson Progress</p>
            <div>
              {renderProgress()}
            </div>
          </div>
          <div className="top-div-div2">
            {tabs.map((item, idx) => (
              item.id === currentTab 
                ? <p className="tdd-current-tab" key={idx}>{item.title}</p>
                : <p key={idx} onClick={() => setCurrentTab(item.id)}>{item.title}</p>
            ))}
          </div>
        </div>
        <div className="co-arena-bottom-div">
          {currentTab === 'class_room' ? renderVideo() : null}
          {currentTab === 'notes'
            ? <div className="co-ar-bt-editor">
                <MUIRichTextEditor
                  label="Type something here..."
                  onSave={save}
                  inlineToolbar={true}
                />
              </div>
            : null}
          <div className="co-ar-bt-div-item">
            <h3>Lesson overview</h3>
            <ul>
              {classes.map((item, idx) => (
                <li key={idx} className={Number(currentVideo) === idx ? 'video-tab-playing' : ''}
                  onClick={() => goToNextVideo(idx)}
                >
                  <div>
                    <input type="checkbox" checked={false} />
                    {Number(currentVideo) === idx
                      ? <p><b>{item.serial_no}. {item.title}</b></p>
                      : <p>{item.serial_no}. {item.title}</p>
                    }
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default CourseArena;
