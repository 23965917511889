import React, { useContext, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
//import classImagePlaceholder from '../assets/images/focused-young-woman-and-girl-drawing-together-at-home-4173332.png';
import avatarPlaceholder from '../assets/images/man-shirt-black-portrait-3565711.png';
import { openConfirmDialog } from '../helpers/popupHelpers';
import { bookLiveCourseInfo } from '../helpers/messages';
import { store } from '../global-store/popupContext';
import { useHistory } from 'react-router-dom';

const OnlineClassesCard = ({ course, action, nextPage }) => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useContext(store);
  
  const [user] = useState(JSON.parse(localStorage.getItem('prepschool_auth')));

  if (!course) return null;
  
  return (
    <div className="online-clses-item">
      <div className="onl-cls-img">
        <img
          src={course.poster}
          alt={course.title}
          style={{cursor:'pointer'}}
          onClick={() => {
            if (user.role === 'tutor')
              history.push('/update-classes/' + course.course_id)
          }}
        />
      </div>
      <div className="onl-cls-content">
        <div className="oci-rating-div">
          <p>{course ? course.title : 'Coding classes for kids'}</p>
          <div className="crd-div">
            <Rating value={3.5} precision={0.5} readOnly />
            <div className="ctc-avatar" title={course.title}>
              <img alt={course.title} src={avatarPlaceholder} />
            </div>
          </div>
        </div>
        <p className="oci-mid-p">{course ? course.description : 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising.'}</p>
        {!user || user.role === 'tutor'
          ? null
          :
            <ColorButton
              variant="contained"
              color="primary"
              classes={{root: classes.btnRoot}}
              href={nextPage ? nextPage : `/users/classes/${course ? course.course_id : 1}`}
              onClick={() => {
                if (!action) return;
                openConfirmDialog(
                  dispatch,
                  () => action(course.course_id, course.tutor),
                  bookLiveCourseInfo
                )
              }}
            >
              {action ? 'book course' : 'buy this course'}
            </ColorButton>
        }
      </div>
    </div>
  );
};

export default OnlineClassesCard;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
  },
}));
