import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { store } from '../../global-store/popupContext';
import OnboardingLayout from '../../components/OnboardingLayout';
import prepschoolAuth from '../../apis/prepschoolAuth';
import { checkPasswordStrength } from '../../helpers/authHelpers';
import { Link, useHistory } from 'react-router-dom';

const Signup = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const role = (new URLSearchParams(props.location.search)).get('role');
  const requiredFields = ['email', 'first_name', 'last_name', 'phone', 'password', 'adult_status', 'terms'];
  if (!role) requiredFields.push('role');

  const initialValues = {
    role: { value: '', validation: true },
    email: { value: '', validation: true },
    first_name: { value: '', validation: true },
    last_name: { value: '', validation: true },
    phone: { value: '', validation: true },
    password: { value: '', validation: true },
    adult_status: { value: '', validation: true },
    terms: { value: '', validation: true },
  }
  
  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [fadeBtn, setFadeBtn] = useState(true);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
    if (name === 'password') 
      checkPasswordStrength(value, setPasswordStrength, setFormValues, formValues);
  }

  const validate = useCallback(() => {
    const valid = requiredFields.every((value) => (
      formValues[value].value && formValues[value].validation
    ));

    setFadeBtn(!valid);
  }, [formValues, requiredFields]);
  
  useEffect(() => { validate(); }, [formValues, validate]);

  const submitForm = () => {
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});
    const signupForm = {
      role: role || formValues.role.value,
      first_name: formValues.first_name.value,
      last_name: formValues.last_name.value,
      email: formValues.email.value,
      phone: formValues.phone.value,
      password: formValues.password.value,
      adult_status: formValues.adult_status.value,
    };
    
    prepschoolAuth.post('/signup', signupForm)
      .then((data) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'success', duration: 6000, message: 'Signup successful!!!' } });
        localStorage.setItem('prepschool_auth', JSON.stringify(data.data.user));
        localStorage.setItem('authorization', data.data.user.token);
        history.push('/verification/email-sent');
        //findRoleDashboard(data.data.user.role, history);
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        console.log(err.message)
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  };

  return (
    <OnboardingLayout>
      <div className="onbdg-title">{role && role === 'tutor' ? 'Sign up as a Tutor' : 'Signup with us'}</div>
      <form>
        {!role && (
          <Autocomplete
            className="form-fields"
            options={['parent', 'student', 'tutor']}
            renderInput={params => (
              <TextField
                {...params}
                label="Signup as:"
                variant="standard"
                fullWidth
                error={!formValues.role.validation}
                style={{ marginBottom:20,width:'100%' }}
              />
            )}
            defaultValue={'parent'}
            value={formValues.role.value}
            onChange={(e, value) => handleFormInput('role', value, /./gi)}
          />
        )}
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          id="firstname-field"
          fullWidth
          autoFocus
          label="First Name"
          variant="standard"
          value={formValues.first_name.value}
          error={!formValues.first_name.validation}
          onChange={(e) => handleFormInput('first_name', e.target.value.trim().replace(/\d/g,''), /^([a-z]+-?[a-z]+){1,255}$/gi)}
        />
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          id="lastname-field"
          fullWidth
          autoFocus
          label="Last Name"
          variant="standard"
          value={formValues.last_name.value}
          error={!formValues.last_name.validation}
          onChange={(e) => handleFormInput('last_name', e.target.value.trim().replace(/\d/g,''), /^([a-z]+-?[a-z]+){1,255}$/gi)}
        />
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          id="email-field"
          fullWidth
          label="Email"
          variant="standard"
          value={formValues.email.value}
          error={!formValues.email.validation}
          onChange={(e) => handleFormInput('email', e.target.value.trim().toLowerCase(), /^[a-z\d][\w.-]+@[a-z\d]+\..+$/gi)}
        />
        <TextField
          className="form-fields"
          style={{marginBottom: 20}}
          id="phone-number-field"
          fullWidth
          label="Phone"
          variant="standard"
          value={formValues.phone.value}
          error={!formValues.phone.validation}
          onChange={(e) => {
            const acceptedValue = e.target.value.replace(/[a-z\W]/gi, '').slice(0,11);
            handleFormInput('phone', acceptedValue, /^\d{11}$/g);
          }}
        />
        <TextField
          className="form-fields"
          id="password-field"
          style={{marginBottom:40}}
          type={showPassword ? 'text' : 'password'}
          label="password"
          placeholder="eg 1d#8%sd232Yds$"
          variant="standard"
          fullWidth
          helperText={passwordStrength}
          value={formValues.password.value}
          error={!formValues.password.validation}
          onChange={(e) => handleFormInput('password', e.target.value, /./gi)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <div className="terms-of-service">
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.adult_status.value}
                onChange={(e) => handleFormInput('adult_status', e.target.checked, /true/)}
                name="checkedA"
              />
            }
            label={<label>Are you older than 18 years?</label>}
          />
        </div>
        <div className="terms-of-service" style={{margin:'0 0 30px'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.terms.value}
                onChange={(e) => handleFormInput('terms', e.target.checked, /true/)}
                name="checkedA"
              />
            }
            label={<label>I agree to the <Link to="#">Terms of Service</Link></label>}
          />
        </div>
      </form>
      <div className="submit-btn-div">
        <div>
          <p>Already have an account?</p>
          <Link to="/login">Login</Link>
        </div>
        <Button
          variant="contained"
          color="primary"
          classes={{root: classes.btnRoot}}
          fullWidth
          disabled={fadeBtn}
          onClick={submitForm}
        >
          Sign up
        </Button>
      </div>
    </OnboardingLayout>
  );
};

export default Signup;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  btnRoot: {
    height: 36,
    backgroundColor: '#0FD9A0',
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    width: 150
  },
}));
