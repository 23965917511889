import React, { useCallback, useEffect, useState } from 'react';
import ChildrenCard from '../../components/ChildrenCard';
import DashboardLayout from '../../components/DashboardLayout';
import RegisteredClassesCard from '../../components/RegisteredClassesCard';
import TutorTile from '../../components/TutorTile';
import OnlineClassesCard from '../../components/OnlineClassesCard';
import { prepschoolClasses } from '../../apis/prepschoolClasses';
import { prepschoolAuth } from '../../apis/prepschoolAuth';

const tabs = [
  { name: 'parents_dashboard', title: 'My Dashboard', href: '/users/parents/dashboard' },
  { name: 'find_lessons', title: 'Find lessons', href: '/user/findclasses' },
  { name: 'booking', title: 'Bookings', href: '/booked/courses' }
];

const ParentsDashboard = () => {
  const [courses, setCourses] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [children, setChildren] = useState([]);
  const [childStudents, setChildStudents] = useState([]);
  const [tutorLiveCourses, setTutorLiveCourses] = useState([]);

  useEffect(() => fetchCourses(), []);
  useEffect(() => fetchTutors(), []);
  useEffect(() => fetchTutorLiveCourses(), []);

  const fetchChildren = useCallback(() => {
    setChildren('loading');
    prepschoolAuth().get('/children')
      .then((data) => {
        setChildren(data.data.data);
        fetchChildProgress(data.data.data);
      })
      .catch((err) => {
        setChildren([]);
      });
  }, []);
  
  useEffect(() => fetchChildren(), [fetchChildren]);

  const fetchCourses = () => {
    setCourses('loading');
    prepschoolClasses().get('/courses', { params: { limit: 3 } })
      .then((data) => {
        setCourses(data.data.data);
      })
      .catch((err) => {
        setCourses([]);
      });
  };

  const fetchTutors = () => {
    setTutors('loading');
    prepschoolAuth().get('/tutors', { params: { limit: 3 } })
      .then((data) => {
        setTutors(data.data.data);
      })
      .catch((err) => {
        setTutors([]);
      });
  }

  const fetchChildProgress = (children) => {
    setChildStudents('loading')
    Promise.all(children.map((child) => {
      return prepschoolClasses().get('/students', { params: { user_id: child.user_id } })
    }))
      .then((data) => {
        setChildStudents(data.map(item => item.data.data).flat());
      })
      .catch((err) => {
        setChildStudents([]);
      });
  };

  const fetchTutorLiveCourses = () => {
    setTutorLiveCourses('loading');
    prepschoolClasses().get('/live-courses', { params: { status: 'published' } })
      .then((data) => {
        setTutorLiveCourses(data.data.data);
      })
      .catch((err) => {
        setTutorLiveCourses([]);
        console.log(err)
      });
  };

  return (
    <DashboardLayout tabs={tabs} currentTab="parents_dashboard" parentsOnly>
      <div className="parents-db-page">
        <section className="parents-db-sec1">
          <div className="pds-top-cards">
            <RegisteredClassesCard children={children} childStudents={childStudents} />
            <ChildrenCard children={children} />
          </div>
        </section>
        {courses[0]
          ?
            <section className="parents-db-sec2">
              <h4>Available Video Courses</h4>
              <div className="online-clses-cont">
                {courses === 'loading'
                  ? <p>Courses loading...</p>
                  : courses.map((item, idx) => (
                      <OnlineClassesCard course={item} key={idx} />
                    ))
                }
              </div>
            </section>
          : null
        }
        {tutorLiveCourses[0]
          ?
            <section className="parents-db-sec2">
              <h4>Available Live Courses</h4>
              <div className="online-clses-cont">
                {tutorLiveCourses === 'loading'
                  ? <p>Live courses loading...</p>
                  : tutorLiveCourses.map((item, idx) => (
                      <OnlineClassesCard
                        key={idx}
                        course={item}
                        nextPage={`/hire-tutor/${item.tutor}`}
                        //action={() => history.push(`/tutor-profile/${tutor.user_id}`)}
                      />
                    ))
                }
              </div>
            </section>
          : null
        }
        {tutors[0]
          ?
            <section className="parents-db-sec3">
              <h4>Top Tutors on PrepSchool</h4>
              <div>
                {tutors === 'loading'
                  ? <p>Tutors loading...</p>
                  : tutors.map((item, idx) => (
                      <TutorTile key={idx} tutor={item} />
                    ))}
              </div>
            </section>
          : null
        }
      </div>
    </DashboardLayout>
  );
};

export default ParentsDashboard;
