import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DashboardLayout from '../../components/DashboardLayout';
import { store } from '../../global-store/popupContext';
import { useHistory } from 'react-router-dom';
import prepschoolAuth from '../../apis/prepschoolAuth';
import { findRoleDashboard } from '../../helpers/authHelpers';

const initialValues = {
  first_name: { value: '', validation: true },
  last_name: { value: '', validation: true },
  phone: { value: '', validation: true },
  email: { value: '', validation: true },
  password: { value: '', validation: true },
}

const tabs = [
  { name: 'parents_dashboard', title: 'My Dashboard', href: '/users/parents/dashboard' },
  { name: 'find_lessons', title: 'Find Courses', href: '/user/findclasses' },
  { name: 'booking', title: 'Bookings', href: '/booked/courses' }
];

const AddAChild = (props) => {
  const history = useHistory();

  const { dispatch } = useContext(store);

  const [formValues, setFormValues] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [fadeBtn, setFadeBtn] = useState(true);

  const handleFormInput = (name, value, regEx) => {
    const formTemp = {...formValues};

    formTemp[name] = { value, validation: regEx.test(value) ? true : false };

    setFormValues(formTemp);
  }

  const validate = useCallback(() => {
    const valid = Object.values(formValues).every((value) => (
      value.value !== '' && value.validation
    ));

    setFadeBtn(!valid);
  }, [formValues]);
  
  useEffect(() => { validate(); }, [formValues, validate]);

  const submitForm = () => {
    dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: true }});
    const signupForm = {
      first_name: formValues.first_name.value,
      last_name: formValues.last_name.value,
      email: formValues.email.value,
      phone: formValues.phone.value,
      password: formValues.password.value,
    }
    
    prepschoolAuth.post('/signup/children', signupForm)
      .then((data) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        dispatch({
          type: 'DISPLAY_ALERT',
          payload: { open: true, type: 'success', duration: 6000, message: data.data.message }
        });
        findRoleDashboard('parent', history);
      })
      .catch((err) => {
        dispatch({ type: 'DISPLAY_PAGE_LOADER', payload: { open: false }});
        console.log(err.message)
        if (err.response)
          dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: err.response.data.error } });
        else dispatch({ type: 'DISPLAY_ALERT', payload: { open: true, type: 'error', duration: 6000, message: 'Poor or no internet connection' } })
      });
  };

  return (
    <DashboardLayout tabs={tabs} parentsOnly>
      <section className="parent-enroll-child">
        <div className="pec-container-div">
          <div className="pec-cont-div1">Add a child or ward</div>
          <div className="pec-cont-div2">
            <TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="child-name-field"
              fullWidth
              label="Child's first name"
              variant="standard"
              value={formValues.first_name.value}
              error={!formValues.first_name.validation}
              onChange={(e) => {
                handleFormInput('first_name', e.target.value.trim().replace(/\d/g,''), /^([a-z]+-?[a-z]+){1,255}$/gi);
              }}
            />
            <TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="child-lastname-field"
              fullWidth
              label="Child's last name"
              variant="standard"
              value={formValues.last_name.value}
              error={!formValues.last_name.validation}
              onChange={(e) => {
                handleFormInput('last_name', e.target.value.trim().replace(/\d/g,''), /^([a-z]+-?[a-z]+){1,255}$/gi);
              }}
            />
            <TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="phone-number-field"
              fullWidth
              label="Phone"
              variant="standard"
              value={formValues.phone.value}
              error={!formValues.phone.validation}
              onChange={(e) => {
                const acceptedValue = e.target.value.replace(/[a-z\W]/gi, '').slice(0,11);
                handleFormInput('phone', acceptedValue, /^\d{11}$/g);
              }}
            />
            <TextField
              className="form-fields"
              style={{marginBottom: 20}}
              id="email-field"
              fullWidth
              label="Email"
              helperText="This Email will be used for logging in"
              variant="standard"
              value={formValues.email.value}
              error={!formValues.email.validation}
              onChange={(e) => {
                handleFormInput('email', e.target.value.trim(), /^[a-z\d][\w.-]+@[a-z\d]+\..+$/gi);
              }}
            />
            <TextField
              className="form-fields"
              id="password-field"
              style={{marginBottom:40}}
              type={showPassword ? 'text' : 'password'}
              label="password"
              placeholder="eg 1d#8%sd232Yds$"
              variant="standard"
              fullWidth
              helperText="Generate a Password for the child to login with"
              value={formValues.password.value}
              error={!formValues.password.validation}
              onChange={(e) => handleFormInput('password', e.target.value, /./gi)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <ColorButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={submitForm}
              disabled={fadeBtn}
            >
              add child
            </ColorButton>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default AddAChild;

const ColorButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#0c946e',
    },
    backgroundColor: '#0FD9A0',
    height: 45,
    color: 'white',
    fontSize: 14,
    letterSpacing: 1.4,
    maxWidth: 200
  },
}))(Button);
